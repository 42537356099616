

import { onMounted, ref, watch } from 'vue'

export default {
  name: 'content-editable',
  props: {
    maxChars: { type: Number, required: false },
    highlight: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' },
    clickToTrigger: { type: Boolean, required: false, default: false },
    content: { type: String, required: true }
  },
  emit: ['content-updated', 'keydown'],
  setup: (props, { emit }) => {
    const contentRef = ref()
    const isContentEditable = ref(false)
    const showPlaceholder = ref(true)
    watch(props, () => {
      if (contentRef.value) {
        contentRef.value.textContent = props.content
      }
    }, { deep: true })
    const contentUpdated = (event) => {
      showPlaceholder.value = event.target.textContent.length === 0
      emit('content-updated', event)
    }

    const handleDoubleClick = (event) => {
      console.log('handleDoubleClick')
      if (props.disabled) return // if disabled is set from outside
      if (props.clickToTrigger) return
      setTimeout(() => {
        contentRef.value.focus()
      }, 0)
      isContentEditable.value = true
    }

    const handleClick = (event) => {
      if (props.disabled) return
      if (!props.clickToTrigger) return
      setTimeout(() => {
        contentRef.value.focus()
      }, 0)
      isContentEditable.value = true
    }

    const handleKeyDown = (event) => {
      if ((event.altKey) && // Check if combination key
            (event.code === 'KeyS')) {
        console.log('preventing default')
        event.preventDefault()
        return
      }
      if (event.key === 'Enter') { // prevent enter by default
        event.preventDefault()
        return
      }
      const content = event.target.textContent

      if (props.maxChars && content.length >= props.maxChars) {
        if (
          event.key !== 'Backspace' &&
            event.key !== 'Delete' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            !(event.metaKey || event.ctrlKey) // Check if not a combination key
        ) {
          console.warn('Max chars of ' + props.maxChars + ' reached.')
          event.preventDefault()
          return
        }
      }

      emit('keydown', event)
    }

    onMounted(() => {
      if (props.content !== '') {
        contentRef.value.textContent = props.content
      }
      showPlaceholder.value = contentRef.value.textContent.length === 0
    })

    return {
      contentRef,
      isContentEditable,
      showPlaceholder,
      contentUpdated,
      handleKeyDown,
      handleDoubleClick,
      handleClick
    }
  }
}
