
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { QuestionShortAnswer } from '@/core/helpers/template/WidgetInterface'
import Editor from '@tinymce/tinymce-vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'question-short-answer-ai-scored',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionShortAnswer, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    const isDisabled = ref(false)

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 100)
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }
    const editorSettingsSolving = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }
    const editorAdvancedSettings = {
      setup: (editor) => {
        editor.on('init', function () {
          // move cursor to end of text
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('NodeChange', () => { // fixes an issue with editor not focusing when it becomes enabled
          if (!editor.getContainer().classList.contains('mce-disabled')) {
            editor.focus()
          }
        })
        editor.on('Focus', () => { // move cursor at the end when focusing
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          persistWidget()
        })
        editor.on('ExecCommand', function (e) {
          const eventsToPersist = ['InsertOrderedList', 'InsertUnorderedList', 'indent', 'outdent']
          if (eventsToPersist.includes(e.command)) {
            persistWidget()
          }
        })
        editor.on('keydown', function (e) { // indent/outdent using tab and shift keys
          if (e.keyCode === 9) {
            e.preventDefault()
            if (e.shiftKey) {
              editor.execCommand('outdent')
              persistWidget()
            } else {
              editor.execCommand('indent')
              persistWidget()
            }
          }
        })
      },
      plugins: ['lists', 'link', 'paste', 'hr'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      placeholder: 'Solve question...',
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',
      font_formats: 'Alegreya=alegreya,serif; Lato=lato,sans-serif; Merriweather=merriweather,serif; Montserrat=montserrat,serif; Open Sans=open sans,sans-serif; Oswald=oswald,sans-serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif; Roboto=roboto,sans-serif;Times New Roman=times new roman,times'
    }

    watch(solvingMode, (newVal, oldVal) => {
      if (newVal) {
        isDisabled.value = false
      } else {
        setTimeout(() => {
          isDisabled.value = true
        }, 500)
      }
    })

    onMounted(() => {
      setTimeout(() => {
        isDisabled.value = true // this is needed to fix a bug for tiny mce not showing placeholder if is initialized as disabled
      }, 500)
    })
    return {
      widgetFound,
      editorSettings,
      solvingMode,
      editorAdvancedSettings,
      isDisabled,
      editorSettingsSolving,
      getApiKey,
      persistWidget
    }
  }
})
