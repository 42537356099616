import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-body-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IframeContainer = _resolveComponent("IframeContainer")!
  const _component_UrlContainer = _resolveComponent("UrlContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "widget-body",
    onDragstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDragStart && _ctx.onDragStart(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.iframeIsDisplayed)
        ? (_openBlock(), _createBlock(_component_IframeContainer, {
            key: 0,
            widget: _ctx.widgetFound,
            readonly: _ctx.readonly,
            onEditClicked: _ctx.onDisplayUrlInput
          }, null, 8, ["widget", "readonly", "onEditClicked"]))
        : (_openBlock(), _createBlock(_component_UrlContainer, {
            key: 1,
            readonly: _ctx.readonly,
            url: _ctx.url,
            "url-is-invalid": _ctx.urlIsInvalid,
            onEmbedClicked: _ctx.onEmbedClicked,
            widget: _ctx.widgetFound
          }, null, 8, ["readonly", "url", "url-is-invalid", "onEmbedClicked", "widget"]))
    ])
  ], 32))
}