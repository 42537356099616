
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey, resizeFont } from '@/core/mc-admin-helpers/tinymce'
import { Flashcard } from '@/core/helpers/template/WidgetInterface'
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: 'flashcard',
  props: {
    widget: { type: Object as () => Flashcard, required: true },
    readonly: { type: Boolean, default: false }
  },
  emits: ['persist-widget'],
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    const content = ref()

    const titleChanged = () => {
      console.log('Title changed...')
      resizeFont(content)
      persistWidget()
    }

    const contentChanged = () => {
      console.log('Content changed...')
      resizeFont(content)
      persistWidget()
    }

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const iconList = [
      'la-bullhorn',
      'la-bullseye',
      'la-binoculars',
      'la-file-alt',
      'la-bell',
      'la-piggy-bank',
      'la-exclamation',
      'la-gift'
    ]

    const onIconChanged = () => {
      const currentIconIndex = iconList.indexOf(widgetFound.value.specific.icon)
      const nextIconIndex = currentIconIndex + 1 === iconList.length ? 0 : currentIconIndex + 1
      widgetFound.value.specific.icon = iconList[nextIconIndex]
      persistWidget()
    }

    const editorAdvancedSettings = {
      setup: (editor) => {
        editor.ui.registry.addContextToolbar('imagealignment', {
          predicate: (node) => node.nodeName.toLowerCase() === 'img',
          items: 'alignleft aligncenter alignright',
          position: 'node',
          scope: 'node'
        })

        editor.ui.registry.addContextToolbar('textselection', {
          predicate: (node) => !editor.selection.isCollapsed(),
          items: 'bold italic underline strikethrough fontselect fontsizeselect alignleft aligncenter alignright alignjustify outdent indent  numlist bullist forecolor backcolor hr link',
          position: 'selection',
          scope: 'node'
        })
        editor.on('ResizeWindow', function () {
          console.log('ResizeWindow')
          resizeFont(content)
        })
        editor.on('init', function () {
          resizeFont(content)
        })

        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          contentChanged()
        })
      },
      plugins: ['lists', 'link', 'paste', 'hr'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '14pt 18pt 24pt 36pt 48pt',
      font_formats: 'Alegreya;Lato;Montserrat;Open Sans;Oswald;Poppins;Raleway;Roboto;Times New Roman'
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      persistWidget,
      titleChanged,
      contentChanged,
      onIconChanged,
      editorSettings,
      editorAdvancedSettings,
      widgetFound,
      content,
      getApiKey,
      resizeFont
    }
  }
})
