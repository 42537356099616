
import { computed, defineComponent, h, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { generateNewSection } from '@/core/helpers/template/template'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { ElNotification } from 'element-plus/es'
import { MenuComponent } from '@/assets/ts/components'
import { Mutations } from '@/store/enums/StoreEnums'
import Draggable from 'vuedraggable'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ContextMenu from '@imengyu/vue3-context-menu'
import ContentEditable from '@/components/builder/ContentEditable/ContentEditable.vue'
import TheSectionBackgroundImageSearchModal from '@/components/builder/TheSectionBackgroundImageSearchModal.vue'
// import BaseTimer from '@/views/mc-admin-builder/BaseTimer.vue'
import { sectionColorClassMap, sectionColorMap } from '@/core/helpers/template/widget'
import { generateIntroSection } from '@/core/helpers/template/sectionTemplate'

export default defineComponent({
  name: 'builder-section-menu',
  components: {
    ContentEditable,
    TheSectionBackgroundImageSearchModal,
    draggable: Draggable
    // BaseTimer
  },
  setup () {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const sections = computed(() => store.getters.getSections)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const activeSection = computed(() => store.getters.getActiveSection)
    const dragStarted = ref(false)
    const contextClickedSectionIndex = ref(-1)
    const contextClickedSection = ref({}) as any
    const emitter = inject('emitter') as any
    const sectionSliderStyle = ref({ left: '4px' })

    watch(activeSectionIndex, (newVal, oldVal) => {
      const movingToRight = newVal > oldVal
      recalculateActiveSectionSliderPosition(newVal, movingToRight)
    })

    const recalculateActiveSectionSliderPosition = (currentSectionIndex, toRight) => {
      const goAheadABit = toRight ? 4 : -4
      const calculateLeft = (0 + (currentSectionIndex * 180) + (currentSectionIndex * 10) + goAheadABit) + 'px'
      sectionSliderStyle.value = { left: calculateLeft }
      setTimeout(() => {
        const calculateLeft = (0 + (currentSectionIndex * 180)) + (currentSectionIndex * 10) + 'px'
        sectionSliderStyle.value = { left: calculateLeft }
      }, 400)
    }

    const onSectionChanged = (sectionIndex) => {
      console.log('on section changed BuilderSectionMenu.vue', sectionIndex)
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
    }

    const onSectionAdded = () => {
      if (activityTemplate.value.usedCounter > 0) return

      if (sections.value.length > 6) {
        ElNotification.warning({
          message: 'You may add a maximum of <strong>' + 7 + '</strong> sections.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      console.log('Adding section...')
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        section: generateNewSection()
      }

      socket.value.emit('ADD_SECTION', {
        eventType: 'ADD_SECTION',
        payload: socketPayload
      })

      setTimeout(() => { // reinitialize sections options menu
        MenuComponent.reinitialization()
      }, 100)
    }

    const activeSectionUuidBeforeDrag = ref()

    const onSectionDragStarted = () => {
      activeSectionUuidBeforeDrag.value = activeSection.value.sectionUuid
    }

    const onSectionDragged = () => {
      console.info('Reordering sections...')
      const oldActiveSectionIndex = sections.value.findIndex((section) => section.sectionUuid === activeSectionUuidBeforeDrag.value)
      console.log('onSectionDragged', oldActiveSectionIndex)
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, oldActiveSectionIndex)

      dragStarted.value = false

      // always enable and remove lock for first section after reordering
      sections.value.forEach((section, index) => {
        if (index === 0) {
          section.settings.enabled = true
          section.settings.locked = false
          section.settings.lockedPassword = ''
          section.settings.lockedPasswordHint = ''
        } else {
          section.settings.enabled = false
        }
      })

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        sections: sections.value
      }

      socket.value.emit('REORDER_SECTIONS', {
        eventType: 'REORDER_SECTIONS',
        payload: socketPayload
      })
    }

    const generateColorContextMenu = () => {
      const colorContextMenu = [] as any
      const colorMenu = {
        label: 'Image',
        icon: h('img', {
          src: '/mc-admin-media/builder-icons/widgets/icons8-image.svg',
          style: {
            width: '20px',
            height: '20px',
            padding: '2px',
            backgroundColor: '#e8e8e8',
            borderRadius: '3px'
          }
        }),
        onClick: () => {
          onSectionColorChanged('background-image')
        }
      }
      colorContextMenu.push(colorMenu)
      for (const key in sectionColorClassMap) {
        const styleObj = {
          width: '20px',
          height: '20px',
          backgroundColor: sectionColorMap[key],
          borderRadius: '50%'
        } as any

        if (key === 'Default') {
          styleObj.boxShadow = 'inset 0 0 0 1px #fafafa'
        }
        const colorMenu = {
          label: key.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          icon: h('div', {
            style: styleObj
          }),
          onClick: () => {
            onSectionColorChanged(key)
          }
        }
        colorContextMenu.push(colorMenu)
      }

      return colorContextMenu
    }

    const onSectionColorChanged = (color) => {
      if (color === 'background-image') {
        emitter.emit('open-background-image-modal', contextClickedSectionIndex.value)
        return
      }

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: {
          activeSectionIndex: activeSectionIndex.value
        },
        section: {
          color: color
        }
      }

      socket.value.emit('EDIT_SECTION', {
        eventType: 'EDIT_SECTION',
        payload: socketPayload
      })

      sections.value[contextClickedSectionIndex.value].color = color
      sections.value[contextClickedSectionIndex.value].backgroundImage = null
    }

    emitter.on('section-background-image-changed', (photoUrl) => {
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: {
          activeSectionIndex: contextClickedSectionIndex.value
        },
        section: {
          backgroundImage: photoUrl
        }
      }

      socket.value.emit('EDIT_SECTION', {
        eventType: 'EDIT_SECTION',
        payload: socketPayload
      })
      console.log('contextClickedSectionIndex.value', contextClickedSectionIndex.value)
      sections.value[contextClickedSectionIndex.value].color = null
      sections.value[contextClickedSectionIndex.value].backgroundImage = photoUrl
      emitter.emit('close-background-image-modal')
    })

    const createTemplate = () => {
      emitter.emit('create-template', true)
    }
    const generateTemplateContextMenu = () => {
      const templateContextMenu = [] as any
      const colorMenu = {
        label: 'Intro Section',
        customClass: 'full-width-image',
        icon: h('img', {
          src: '/mc-admin-media/builder-icons/section-templates/intro-section.jpg',
          style: {
            width: '240px',
            height: '135px',
            padding: '2px',
            backgroundColor: '#e8e8e8',
            borderRadius: '3px'
          }
        }),
        onClick: () => {
          onBuildFromSectionTemplateClicked('intro-section')
        }
      }
      templateContextMenu.push(colorMenu)

      return templateContextMenu
    }

    const onBuildFromSectionTemplateClicked = (sectionTemplate) => {
      if (activityTemplate.value.usedCounter > 0) return

      if (sections.value.length > 6) {
        ElNotification.warning({
          message: 'You may add a maximum of <strong>' + 7 + '</strong> sections.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }

      let section = null as any
      if (sectionTemplate === 'intro-section') {
        section = generateIntroSection()
      }
      if (section !== null) {
        console.log('Adding section from section template...')
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          section: section
        }

        socket.value.emit('ADD_SECTION', {
          eventType: 'ADD_SECTION',
          payload: socketPayload
        })

        setTimeout(() => { // reinitialize sections options menu
          MenuComponent.reinitialization()
        }, 100)
      }
    }

    const onContextMenuOpened = (e: MouseEvent, section, sectionIndex) => {
      contextClickedSectionIndex.value = sectionIndex
      contextClickedSection.value = section
      e.preventDefault()
      const menuOptions = [{
        label: 'Background',
        children: generateColorContextMenu(),
        icon: h('img', {
          src: '/mc-admin-media/builder-icons/widget-actions/icons8-fill-color.svg',
          style: {
            width: '15px',
            height: '15px'
          }
        })
      },
      {
        label: 'Template',
        children: generateTemplateContextMenu(),
        icon: h('img', {
          src: '/mc-admin-media/builder-icons/widget-actions/3d-reality-room-scene-virtual-icon.svg',
          style: {
            width: '15px',
            height: '15px'
          }
        })
      },
      {
        label: 'Delete',
        onClick: () => {
          if (activityTemplate.value.usedCounter > 0) {
            return
          }

          if (sections.value.length <= 1) {
            ElNotification.warning({
              message: 'The activity must have at least one section excluding Intro.',
              dangerouslyUseHTMLString: true,
              customClass: 'houston-notification'
            })
            return
          }

          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-light'
            },
            buttonsStyling: false
          })

          swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            html: '<div>The section and it\'s contents will be removed. This action is not reversible.</div>',
            animation: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              const socketPayload = {
                templateUuid: activityTemplate.value.activityTemplateUuid,
                locator: {
                  activeSectionIndex: contextClickedSectionIndex.value
                }
              }

              socket.value.emit('REMOVE_SECTION', {
                eventType: 'REMOVE_SECTION',
                payload: socketPayload
              })

              // remove the lock for the second section if the first section is deleted
              if (contextClickedSectionIndex.value === 0 && sections.value[1].settings.locked === true) {
                const socketPayload = {
                  templateUuid: activityTemplate.value.activityTemplateUuid,
                  locator: {
                    activeSectionIndex: 0
                  },
                  section: {
                    settings: {
                      enabled: true,
                      locked: false,
                      lockedPassword: '',
                      lockedPasswordHint: ''
                    }
                  }
                }

                socket.value.emit('EDIT_SECTION', {
                  eventType: 'EDIT_SECTION',
                  payload: socketPayload
                })
              }
            }
          })
        },
        icon: h('img', {
          src: '/mc-admin-media/builder-icons/widget-actions/icons8-remove.svg',
          style: {
            width: '15px',
            height: '15px'
          }
        })
      }]

      if (contextClickedSection.value.settings.locked) {
        menuOptions.unshift({
          label: 'Remove Lock',
          onClick: () => {
            const socketPayload = {
              templateUuid: activityTemplate.value.activityTemplateUuid,
              locator: {
                activeSectionIndex: contextClickedSectionIndex.value
              },
              section: {
                settings: {
                  enabled: false,
                  locked: false,
                  lockedPassword: '',
                  lockedPasswordHint: ''
                }
              }
            }

            socket.value.emit('EDIT_SECTION', {
              eventType: 'EDIT_SECTION',
              payload: socketPayload
            })
          },
          icon: h('i', {
            class: 'fas fa-unlock-alt fs-6 text-dark'
          })

        })
        menuOptions.unshift({
          label: 'Edit Lock',
          onClick: () => {
            emitter.emit('show-lock-section-modal', {
              state: true,
              section: contextClickedSection.value,
              sectionIndex: contextClickedSectionIndex.value
            })
          },
          icon: h('i', {
            class: 'fas fa-user-lock fs-6 text-dark'
          })

        })
      }

      if (!contextClickedSection.value.settings.locked && contextClickedSectionIndex.value !== 0) {
        menuOptions.unshift({
          label: 'Lock',
          onClick: () => {
            emitter.emit('show-lock-section-modal', {
              state: true,
              section: contextClickedSection.value,
              sectionIndex: contextClickedSectionIndex.value
            })
          },
          icon: h('i', {
            class: 'fas fa-user-lock fs-6 text-dark'
          })

        })
      }

      ContextMenu.showContextMenu({
        x: e.x,
        y: e.y,
        items: menuOptions
      })
    }

    let sectionUpdateTimeout = 0
    const onSectionTitleUpdated = (event) => {
      const sectionTitle = event.target.textContent
      clearTimeout(sectionUpdateTimeout)
      sectionUpdateTimeout = setTimeout(() => {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: activeSectionIndex.value
          },
          section: {
            title: sectionTitle
          }
        }

        socket.value.emit('EDIT_SECTION', {
          eventType: 'EDIT_SECTION',
          payload: socketPayload
        })
      }, 0)
    }

    return {
      sections,
      activeSectionIndex,
      activityTemplate,
      dragStarted,
      contextClickedSection,
      onContextMenuOpened,
      getApiKey,
      onSectionChanged,
      onSectionAdded,
      onSectionDragged,
      onSectionDragStarted,
      onSectionTitleUpdated,
      contextClickedSectionIndex,
      sectionSliderStyle,
      createTemplate
    }
  }
})
