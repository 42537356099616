
import { defineComponent } from 'vue'
import { Shape } from '@/core/helpers/template/WidgetInterface'

export default defineComponent({
  name: 'shape',
  props: {
    widget: { type: Object as () => Shape, required: true },
    readonly: { type: Boolean, default: false },
    resizeInfo: { type: Object }
  },
  emits: ['persist-widget']
})
