
import { computed, defineComponent, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ImageView360 } from '@/core/helpers/template/WidgetInterface.ts'
import { Actions, Mutations } from '@/store/enums/StoreEnums.ts'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'
import { View360, EquirectProjection, EVENTS, ProjectionChangeEvent, LoadStartEvent } from '@egjs/vue3-view360'
import '@egjs/vue3-view360/css/view360.min.css'
import { vec3, mat4 } from 'gl-matrix'
import { uuid } from 'vue-uuid'
import ContentEditable from '@/components/builder/ContentEditable/ContentEditable.vue'
import { styledTextCardBackgroundColors } from '@/views/mc-admin-builder/Widget/TextCardStyled.vue'
import 'vue-color-kit/dist/vue-color-kit.css'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { generateWidget } from '@/core/helpers/template/widget'
export default defineComponent({
  name: 'image-view-360',
  props: {
    widget: { type: Object as () => ImageView360, required: true },
    readonly: { type: Boolean, default: false },
    isLandscape: { type: Boolean, default: false }
  },
  components: {
    ContentEditable,
    View360
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const emitter = inject('emitter') as any
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const viewer = ref(null) as any

    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentAccount = computed(() => store.getters.currentAccount)
    const selectedWidgetScene = computed(() => store.getters.getSelectedSceneForWidget(props.widget.widgetUuid))
    const scenesFor360Image = computed(() => store.getters.getScenesFor360ImageWidget(props.widget.widgetUuid)).value
    const scenesToLink = (widgetUuid, scene) => computed(() => store.getters.getScenesExceptSelectedFor360ImageWidget(widgetUuid, scene)).value
    const hotspotsFor360ImageScene = (widgetUuid, sceneUuid) => computed(() => store.getters.getHotspotsFor360ImageScene(widgetUuid, sceneUuid)).value
    const selectedHotspotForScene = (sceneUuid) => computed(() => store.getters.getSelectedHotspotForScene(sceneUuid)).value
    const uploadFileProgress = (widgetUuid) => computed(() => store.getters.getFileUploadProgressForWidget(widgetUuid))
    const fileInput = ref()
    const isDragging = ref(false)
    const textSize = ref(20)
    const onIncreaseClicked = () => {
      if (selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form === 'text') {
        textSize.value = textSize.value + 1
      }
    }
    let hotspotTextUpdateTimeout = 0
    const onHotspotTextUpdated = (event) => {
      const hotspotTextValue = event.target.textContent
      clearTimeout(hotspotTextUpdateTimeout)
      hotspotTextUpdateTimeout = setTimeout(() => {
        selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).textValue = hotspotTextValue
        const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
        const payload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: props.widget.widgetUuid,
          hotspot: selectedHotspot
        }
        socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
          eventType: 'IMAGE_360_EDIT_HOTSPOT',
          payload: payload
        })
      }, 1000)
    }
    const handleCommandMakeItRound = (command: any) => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form = command.type
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }

    const onClickToChangeColor = (color) => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.color = color
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }
    const handleCommandChangeSize = (command: any) => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.size = command.size
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }
    const handleCommandLinkToScene = (command: any) => {
      const sceneStripped = {
        sceneUuid: command.scene.sceneUuid,
        image: command.scene.image
      }
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).linkTo = sceneStripped
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).modalWidget = []
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }

    const showLinkToWidgetModal = ref(false)
    const linkToWidget = ref()
    const handleCommandLinkToWidget = (command: any) => {
      showLinkToWidgetModal.value = true
      const selectedModalWidget = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).modalWidget
      if (selectedModalWidget.widgetUuid && selectedModalWidget.type === command.widget) {
        console.log('emiting ', selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).modalWidget)
        emitter.emit('show-link-to-widget-modal', { widgetUuid: props.widget.widgetUuid, hotspot: selectedHotspotForScene(selectedWidgetScene.value.sceneUuid) })
        return
      }
      const widget: Widget | null = generateWidget(command.widget, 1, 1)
      if (widget) {
        widget.widgetUuid = uuid.v4()
        widget.specific.color = 'white'
        // const payload = {
        //   widget: widget
        // }
        linkToWidget.value = widget
        selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).modalWidget = linkToWidget.value
        selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).linkTo = []
        const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
        const payload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: props.widget.widgetUuid,
          hotspot: selectedHotspot
        }
        socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
          eventType: 'IMAGE_360_EDIT_HOTSPOT',
          payload: payload
        })

        emitter.emit('show-link-to-widget-modal', { widgetUuid: props.widget.widgetUuid, hotspot: selectedHotspotForScene(selectedWidgetScene.value.sceneUuid) })
      }
    }
    const onMakeItRoundClicked = () => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form = 'round'
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }
    const onMakeItSquareClicked = () => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form = 'square'
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }
    const onIWantTextClicked = () => {
      selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form = 'text'
      const selectedHotspot = selectedHotspotForScene(selectedWidgetScene.value.sceneUuid)
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: props.widget.widgetUuid,
        hotspot: selectedHotspot
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payload
      })
    }
    const onDecreaseClicked = () => {
      if (selectedHotspotForScene(selectedWidgetScene.value.sceneUuid).settings.form === 'text') {
        textSize.value = textSize.value - 1
      }
    }
    const onDeleteHotspotClicked = (widgetUuid, hotspotUuid) => {
      const payload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: widgetUuid,
        hotspotUuid: hotspotUuid
      }
      socket.value.emit('IMAGE_360_REMOVE_HOTSPOT', {
        eventType: 'IMAGE_360_REMOVE_HOTSPOT',
        payload: payload
      })
    }
    const handleHotspotClick = (sceneUuid, hotspot) => {
      if (solvingMode.value) {
        if (hotspot.linkTo && hotspot.linkTo.image) {
          const originalSrc = hotspot.linkTo.image!.selfLinks?.original
          const stringSrc = buildTemplateFileUri(
                  hotspot.linkTo.image!.attachmentUuid,
                  currentAccount.value.company.name,
                  originalSrc || null
          )
          if (projection.value) {
            projection.value.src = stringSrc
          } else {
            projection.value = new EquirectProjection({
              src: stringSrc || '', // Provide an empty string as the default value
              video: false
            })

            if (viewer.value) {
              viewer.value._.data.view360.load(projection.value)
            }
          }
          const payload = {} as any
          payload.widgetUuid = props.widget.widgetUuid
          payload.scene = hotspot.linkTo
          store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, payload)
        }
        if (hotspot.modalWidget && hotspot.modalWidget.widgetUuid) {
          emitter.emit('show-link-to-widget-modal', { widgetUuid: props.widget.widgetUuid, hotspot: hotspot })
        }
      }
      const payload = {
        sceneUuid: sceneUuid,
        hotspot: hotspot
      }
      store.commit(Mutations.SET_SELECTED_HOTSPOT_IN_SCENE, payload)
    }
    emitter.on('widget-move', (widgetUuid) => {
      if (widgetUuid !== props.widget.widgetUuid) return
      if (!isDragging.value) {
        isDragging.value = true

        // captureIframe()
      }
    })
    emitter.on('widget-moved', (widgetUuid) => {
      if (widgetUuid !== props.widget.widgetUuid) return
      if (isDragging.value) {
        isDragging.value = false
      }
    })
    // const currentWidget = props.widget as any
    const onImageChanged = (event) => {
      if (props.readonly) return

      const updateProgressPayload = {
        widgetUuid: props.widget.widgetUuid,
        progress: 0
      }

      store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)

      const data = new FormData()
      const newSceneUuid = uuid.v4()
      data.append('content', event.target.files[0])
      data.append('sceneUuid', newSceneUuid)
      data.append('widgetUuid', props.widget.widgetUuid)
      data.append('templateUuid', activityTemplate.value.activityTemplateUuid)

      const payload = {
        data: data,
        templateUuid: activityTemplate.value.activityTemplateUuid,
        config: {
          headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
          onUploadProgress: (progressEvent) => {
            updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
          }
        }
      }

      store.dispatch(Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        const updatePayload = {
          widgetUuid: props.widget.widgetUuid,
          sceneUuid: newSceneUuid,
          attachmentMeta: response.payload.meta
        } as any
        store.commit(Mutations.UPDATE_WIDGET_ADD_SCENE, updatePayload)
        // Get all elements with the desired class names
      }).catch((response) => {
        console.log('response error', response)
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    const elements = ref([])
    emitter.on('upload-file-clicked', (widgetUuid) => {
      console.log('Emitted UPLOAD event for widget: ', widgetUuid)
      const element: any = elements.value.find((element: any) => element.uuid === widgetUuid)
      if (element) {
        element.el.click()
        elements.value = []
      }
    })

    // const hotspotCounter = ref(0)
    const projection = ref(null) as any
    socket.value.on('IMAGE_360_HOTSPOT_ADDED', (eventData) => {
      const payload = {
        widgetUuid: eventData.widgetUuid,
        sceneUuid: eventData.scene.sceneUuid,
        hotspot: eventData.hotspot
      }
      store.commit(Mutations.UPDATE_WIDGET_ADD_HOTSPOT_TO_SCENE, payload)
      setTimeout(() => {
        if (viewer.value) {
          viewer.value._.data.view360.hotspot.refresh()
          // .viewer.value.hotspot.refresh()
          viewer.value._.data.view360.hotspot.render(viewer.value._.data.view360._camera)
        }
      }, 300)
    })
    socket.value.on('IMAGE_360_HOTSPOT_REMOVED', (eventData) => {
      const payload = {
        widgetUuid: eventData.widgetUuid,
        hotspotUuid: eventData.hotspotUuid
      }
      store.commit(Mutations.UPDATE_WIDGET_REMOVE_HOTSPOT, payload)
      setTimeout(() => {
        if (viewer.value) {
          viewer.value._.data.view360.hotspot.refresh()
          viewer.value._.data.view360.hotspot.render(viewer.value._.data.view360._camera)
        }
      }, 300)
    })
    watch(selectedWidgetScene, (old, newValue) => {
      if (selectedWidgetScene.value) {
        const originalSrc = selectedWidgetScene.value.image!.selfLinks?.original
        const stringSrc = buildTemplateFileUri(
                  selectedWidgetScene.value.image!.attachmentUuid,
                  currentAccount.value.company.name,
                  originalSrc || null
        ) || ''
        if (projection.value) {
          projection.value.src = stringSrc
        } else {
          projection.value = new EquirectProjection({
            src: stringSrc || '', // Provide an empty string as the default value
            video: false
          })

          if (viewer.value) {
            viewer.value._.data.view360.load(projection.value)
          }
        }
      }
    })
    // needed for adding hotspots

    const handleDoubleClick = (evt) => {
      if (typeof viewer.value._.data.view360._renderer !== 'undefined') {
        const x = 2 * (evt.offsetX / viewer.value._.data.view360._renderer.width) - 1
        const y = -2 * (evt.offsetY / viewer.value._.data.view360._renderer.height) + 1
        const pos = vec3.fromValues(x, y, 0.5)
        const invProjMat = mat4.invert(
          mat4.create(),
          viewer.value._.data.view360._camera.projectionMatrix
        )
        const invViewMat = mat4.invert(
          mat4.create(),
          viewer.value._.data.view360._camera.viewMatrix
        )
        vec3.transformMat4(pos, pos, invProjMat)
        vec3.transformMat4(pos, pos, invViewMat)

        const clickedDir = vec3.normalize(pos, pos)

        const sceneStripped = {
          sceneUuid: selectedWidgetScene.value.sceneUuid,
          image: selectedWidgetScene.value.image
        }
        const payloadAddHotspot = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: props.widget.widgetUuid,
          scene: sceneStripped,
          position: {
            X: clickedDir[0].toFixed(4),
            Y: clickedDir[1].toFixed(4),
            Z: clickedDir[2].toFixed(4)
          }
        }

        socket.value.emit('IMAGE_360_ADD_HOTSPOT', {
          eventType: 'IMAGE_360_ADD_HOTSPOT',
          payload: payloadAddHotspot
        })
      }
    }
    const projectionLoading = ref(false)
    const onProjectionChange = (evt: ProjectionChangeEvent) => {
      projectionLoading.value = false
    }
    const onLoadStart = (evt: LoadStartEvent) => {
      projectionLoading.value = true
    }

    watch(viewer, () => {
      if (viewer.value) {
        viewer.value._.data.view360.on(EVENTS.PROJECTION_CHANGE, onProjectionChange)
        viewer.value._.data.view360.on(EVENTS.LOAD_START, onLoadStart)
      }
    })

    onMounted(() => {
      // Typescript

      if (selectedWidgetScene.value) {
        const originalSrc = selectedWidgetScene.value.image!.selfLinks?.original
        const stringSrc = buildTemplateFileUri(
                selectedWidgetScene.value.image!.attachmentUuid,
                currentAccount.value.company.name,
                originalSrc || null
        )
        projection.value = new EquirectProjection({
          src: stringSrc || '', // Provide an empty string as the default value
          video: false
        })

        if (viewer.value) {
          setTimeout(() => {
            viewer.value._.data.view360.load(projection.value)
          }, 0)
        }
      } else {
        if (
          props.widget.specific &&
                props.widget.specific.scenes[0] &&
                props.widget.specific.scenes[0].image &&
                props.widget.specific.scenes[0].image.selfLinks &&
                props.widget.specific.scenes[0].image.selfLinks.original &&
                typeof props.widget.specific.scenes[0].image.selfLinks.original !== 'undefined'
        ) {
          const originalSrc = props.widget.specific.scenes[0].image.selfLinks?.original

          const stringSrc = buildTemplateFileUri(
            props.widget.specific.scenes[0].image.attachmentUuid,
            currentAccount.value.company.name,
            originalSrc || null
          )
          projection.value = new EquirectProjection({
            src: stringSrc || '', // Provide an empty string as the default value
            video: false
          })

          viewer.value._.data.view360.load(projection.value)
          const payload = {} as any
          payload.widgetUuid = props.widget.widgetUuid
          payload.scene = props.widget.specific.scenes[0]
          store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, payload)
        }
      }
      emitter.on('upload-file-clicked', (widgetUuid) => {
        const element: any = elements.value.find((element: any) => element.uuid === widgetUuid)
        if (element) {
          element.el.click()
        }
      })
    })

    onUnmounted(() => {
      elements.value = []
      if (viewer.value) {
        viewer.value._.data.view360.destroy()
      }
    })

    return {
      activityTemplate,
      currentAccount,
      elements,
      fileInput,
      viewer,
      projection,
      isDragging,
      projectionLoading,
      scenesFor360Image,
      selectedWidgetScene,
      textSize,
      solvingMode,
      showLinkToWidgetModal,
      linkToWidget,
      handleDoubleClick,
      onHotspotTextUpdated,
      scenesToLink,
      handleCommandLinkToScene,
      onDeleteHotspotClicked,
      handleCommandMakeItRound,
      onMakeItRoundClicked,
      onMakeItSquareClicked,
      onIWantTextClicked,
      onIncreaseClicked,
      onDecreaseClicked,
      handleHotspotClick,
      hotspotsFor360ImageScene,
      selectedHotspotForScene,
      handleCommandLinkToWidget,
      buildTemplateFileUri,
      onImageChanged,
      uploadFileProgress,
      handleCommandChangeSize,
      onClickToChangeColor,
      styledTextCardBackgroundColors
    }
  }
})
