
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { ImageCard } from '@/core/helpers/template/WidgetInterface.ts'
import { Actions, Mutations } from '@/store/enums/StoreEnums.ts'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'

export default defineComponent({
  name: 'icon-card',
  props: {
    widget: { type: Object as () => ImageCard, required: true },
    readonly: { type: Boolean, default: false },
    isLandscape: { type: Boolean, default: false }
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    const currentAccount = computed(() => store.getters.currentAccount)
    const uploadFileProgress = (widgetUuid) => computed(() => store.getters.getFileUploadProgressForWidget(widgetUuid))
    const persistWidget = () => {
      console.log('Persisting widget...')
      const currentWidgetInstance = widgetFound.value
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    onUnmounted(() => {
      elements.value = []
    })

    const fileInput = ref()
    const emitter = inject('emitter') as any // Inject `emitter`
    const onImageChanged = (event) => {
      if (props.readonly) {
        return
      }
      const updateProgressPayload = {
        widgetUuid: props.widget.widgetUuid,
        progress: 0
      }
      store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)

      const data = new FormData()
      data.append('content', event.target.files[0])
      data.append('widgetUuid', props.widget.widgetUuid)
      data.append('templateUuid', activityTemplate.value.activityTemplateUuid)

      const config = {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
        onUploadProgress: (progressEvent) => {
          updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        }
      }
      const payload = {
        data: data,
        templateUuid: activityTemplate.value.activityTemplateUuid,
        config: config
      }

      store.dispatch(Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        const updatePayload = {
          widgetUuid: props.widget.widgetUuid,
          attachmentMeta: response.payload.meta
        } as any
        store.commit(Mutations.UPDATE_WIDGET_SET_IMAGE, updatePayload)
      }).catch((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    const elements = ref([])
    emitter.on('upload-file-clicked', (widgetUuid) => {
      const element: any = elements.value.find((element: any) => element.uuid === widgetUuid)
      if (element) {
        element.el.click()
        elements.value = []
      }
    })
    onMounted(() => {
      emitter.on('upload-file-clicked', (widgetUuid) => {
        const element: any = elements.value.find((element: any) => element.uuid === widgetUuid)
        if (element) {
          element.el.click()
        }
      })
    })

    const isHoveringImage = ref(false)

    const onImageMouseOver = () => {
      // isHoveringImage.value = true
    }

    const onImageMouseOut = () => {
      // isHoveringImage.value = false
    }

    return {
      isHoveringImage,
      fileInput,
      uploadFileProgress,
      activityTemplate,
      currentAccount,
      buildTemplateFileUri,
      persistWidget,
      onImageChanged,
      onImageMouseOver,
      onImageMouseOut,
      elements
    }
  }
})
