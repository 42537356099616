
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { DragAndDropGroup } from '@/core/helpers/template/WidgetInterface'
import { ElNotification } from 'element-plus/es'
import { uuid } from 'vue-uuid'
import Draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: 'drag-and-drop-group',
  props: {
    widget: { type: Object as () => DragAndDropGroup, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor,
    draggable: Draggable
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const dragStarted = ref(false)

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const onListItemAdded = () => {
      console.log('Adding list item...')
      widgetFound.value.specific.itemList.unshift({
        itemUuid: uuid.v4(),
        content: ''
      })
      persistWidget()
    }

    const onItemListChanged = (itemUuid, updatedItemContent) => {
      // search and replace in solution
      const solution = widgetFound.value.specific.solution
      for (let s = 0; s < solution.length; s++) {
        for (let si = 0; si < solution[s].itemList.length; si++) {
          if (solution[s].itemList[si].itemUuid === itemUuid) {
            solution[s].itemList[si].content = updatedItemContent
          }
        }
      }
      persistWidget()
    }

    const onListItemRemoved = (itemUuid) => {
      console.log('Removing item with UUID:', itemUuid)
      if (widgetFound.value.specific.itemList.length <= 1) {
        ElNotification.warning({
          message: 'You may not remove the <strong>last</strong> item.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      // remove from itemList
      const itemKey = widgetFound.value.specific.itemList.findIndex(i => i.itemUuid === itemUuid)
      widgetFound.value.specific.itemList.splice(itemKey, 1)
      // remove from solution in case it exists
      const solution = widgetFound.value.specific.solution
      for (let s = 0; s < solution.length; s++) {
        for (let si = 0; si < solution[s].itemList.length; si++) {
          if (solution[s].itemList[si].itemUuid === itemUuid) {
            solution[s].itemList = solution[s].itemList.filter(sol => sol.itemUuid !== itemUuid)
          }
        }
      }
      persistWidget()
    }

    const onListItemDragged = () => {
      console.log('Reordering available items...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemDraggedToColumn = () => {
      console.log('Dragging item to column...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemClonedToColumn = (itemList) => {
      console.log('Cloning item to column...')
      return {
        solutionItemUuid: uuid.v4(),
        itemUuid: itemList.itemUuid,
        content: itemList.content
      }
    }

    const onSolutionItemRemoved = (columnUuid, solutionItemUuid) => {
      console.log('Removing solution item with solution item uuid ', solutionItemUuid)
      const solution = widgetFound.value.specific.solution
      const solutionColumnKey = solution.findIndex(s => s.columnUuid === columnUuid)
      const solutionItemKey = solution[solutionColumnKey].itemList.findIndex(si => si.solutionItemUuid === solutionItemUuid)

      widgetFound.value.specific.solution[solutionColumnKey].itemList.splice(solutionItemKey, 1)
      persistWidget()
    }

    const getSolutionByColumnUuid = (columnUuid) => {
      const solutionIndex = widgetFound.value.specific.solution.findIndex(s => s.columnUuid === columnUuid)
      return widgetFound.value.specific.solution[solutionIndex].itemList
    }

    const getSolutionIndexByItemUuid = (itemUuid) => {
      return widgetFound.value.specific.solution[0].itemList.findIndex(si => si.itemUuid === itemUuid)
    }

    const getSolutionAvailableItems = () => {
      console.log('Calculating solution available items...')
      const solutionExistingItemsUuids = widgetFound.value.specific.solution[0].itemList
      return widgetFound.value.specific.itemList.filter(
        item => typeof solutionExistingItemsUuids.find(si => si.itemUuid === item.itemUuid) !== 'object'
      )
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    const colorClassMap: { [key: string]: string } = {
      white: 'ghost-widget-white',
      orange: 'ghost-widget-orange',
      blue: 'ghost-widget-blue',
      red: 'ghost-widget-red',
      green: 'ghost-widget-green',
      purple: 'ghost-widget-purple',
      gray: 'ghost-widget-gray'
    }

    return {
      solvingMode,
      dragStarted,
      editorSettings,
      colorClassMap,
      widgetFound,
      getApiKey,
      persistWidget,
      onListItemAdded,
      onItemListChanged,
      onListItemRemoved,
      onListItemDragged,
      onItemDraggedToColumn,
      onItemClonedToColumn,
      onSolutionItemRemoved,
      getSolutionByColumnUuid,
      getSolutionIndexByItemUuid,
      getSolutionAvailableItems
    }
  }
})
