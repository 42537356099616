import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["widget-body", { 'no-drag': !_ctx.isDisabled }]),
    style: {"overflow-y":"scroll"}
  }, [
    _createVNode(_component_editor, {
      modelValue: _ctx.widgetFound.specific.content,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.widgetFound.specific.content) = $event)),
      "api-key": _ctx.getApiKey(),
      disabled: _ctx.isDisabled,
      init: _ctx.editorSettings,
      onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDisabled = false)),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.persistWidget())),
      onPaste: _cache[3] || (_cache[3] = ($event: any) => (_ctx.persistWidget())),
      onUndo: _cache[4] || (_cache[4] = ($event: any) => (_ctx.persistWidget())),
      placeholder: "Add some default content if needed",
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.persistWidget())),
      class: "h-100"
    }, null, 8, ["modelValue", "api-key", "disabled", "init"])
  ], 2)), [
    [_directive_click_outside, _ctx.vcoConfig]
  ])
}