import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "widget-body" }
const _hoisted_2 = { class: "widget-body-content" }
const _hoisted_3 = {
  ref: "content",
  class: "code-content no-drag"
}
const _hoisted_4 = { class: "toolbar no-drag" }
const _hoisted_5 = {
  class: "badge houston-badge-steel",
  type: "button",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "left-end",
  "data-kt-menu-flip": "center-end"
}
const _hoisted_6 = {
  class: "menu menu-sub menu-sub-dropdown menu-column py-1 mx-2 w-auto",
  style: {"box-shadow":"0 0 6px rgb(160 160 160 / 25%)"},
  "data-kt-menu": true
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "color-settings-item mx-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_codemirror = _resolveComponent("codemirror")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_codemirror, {
          modelValue: _ctx.widgetFound.specific.code,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.widgetFound.specific.code) = $event)),
          placeholder: "Code goes here...",
          style: { height: '400px' },
          autofocus: false,
          "indent-with-tab": true,
          "tab-size": 2,
          extensions: _ctx.extensions,
          disabled: _ctx.solvingMode,
          onReady: _ctx.handleReady,
          onChange: _ctx.persistWidget
        }, null, 8, ["modelValue", "extensions", "disabled", "onReady", "onChange"]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.widgetFound.specific.language), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "menu-item px-1",
                key: language
              }, [
                _createElementVNode("a", {
                  href: "#",
                  class: "menu-link p-1",
                  onClick: ($event: any) => (_ctx.onLanguageChanged(language))
                }, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(language), 1)
                ], 8, _hoisted_7)
              ]))
            }), 128))
          ])
        ], 512), [
          [_vShow, !_ctx.solvingMode]
        ])
      ], 512)
    ])
  ]))
}