
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import {
  actionWidgetTypes,
  CODE,
  DRAG_AND_DROP_GROUP,
  DRAG_AND_DROP_ORDER,
  FILE_UPLOAD,
  FILE_UPLOAD_STYLED,
  FLASHCARD,
  HEADING, IMAGE_CARD,
  QUESTION_FREE_ANSWER,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SHORT_ANSWER,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEAM_READINESS,
  TEXT_CARD,
  TEXT_CARD_STYLED, VIDEO_EMBEDDED,
  QUESTION_SHORT_ANSWER_AI_SCORED, SHAPE, REFLECTION_POST_IT
} from '@/core/helpers/template/widgetType'
import { Mutations } from '@/store/enums/StoreEnums'
import { uuid } from 'vue-uuid'
import { computeWidgetColorsByType, colorMap } from '@/core/helpers/template/widget'
import { CirclePlusFilled, PictureFilled } from '@element-plus/icons-vue'
export default defineComponent({
  name: 'builder-widget-actions',
  emit: ['open-widget-settings', 'widget-delete'],
  props: {
    widget: { type: Object as () => Widget, required: true },
    widgetIndex: { type: Number, required: true }
  },
  setup (props, { emit }) {
    const store = useStore()
    const emitter = inject('emitter') as any

    const currentWidget = computed(() => store.getters.getActiveWidget)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const getActiveWidgets = computed(() => store.getters.getActiveWidgets)
    const groupedWidgets = computed(() => store.getters.getGroupedWidgets)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const revealMobileHiddenWidgets = computed(() => store.getters.getRevealMobileHiddenWidgets)
    const socket = computed(() => store.getters.getSocket)
    const scenesFor360Image = (widgetUuid) => computed(() => store.getters.getScenesFor360ImageWidget(widgetUuid)).value
    const selectedWidgetScene = (widgetUuid) => computed(() => store.getters.getSelectedSceneForWidget(widgetUuid)).value

    const removingWidgetInProgress = ref([]) as any
    const handleCommandImage360 = (command: any) => {
      if (typeof command === 'object') {
        if (command.type === 'addScene') {
          emitter.emit('upload-file-clicked', command.widgetUuid)
        }
        if (command.type === 'generateAiScene') {
          emitter.emit('show-generate-image-modal', command.widgetUuid)
        }
        if (command.type === 'changeScene') {
          if (!command.scene.image.selfLinks) {
            return
          }
          const selectedScenePayload = {
            widgetUuid: command.widgetUuid,
            scene: command.scene
          }
          store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, selectedScenePayload)
        }
      }
      console.log('clicked', command)
    }
    const onRemoveWidgetClicked = (widgetUuid) => {
      // removingWidgetInProgress.value = true
      emit('widget-delete', widgetUuid)
    }

    const onRegenerateClicked = (widgetUuid) => {
      emitter.emit('regenerate-clicked', widgetUuid)
      console.log('widgetUuid', widgetUuid)
    }

    const onPositionChanged = (event, position) => {
      switch (position) {
        case 'w':
          currentWidget.value.position.w = event.target.valueAsNumber
          persistWidget()
          break
        case 'h':
          currentWidget.value.position.h = event.target.valueAsNumber
          persistWidget()
          break
        case 'x':
          currentWidget.value.position.x = event.target.valueAsNumber
          persistWidget()
          break
        case 'y':
          currentWidget.value.position.y = event.target.valueAsNumber
          persistWidget()
          break
      }
    }

    emitter.on('confirm-widget-delete', (widgetUuid) => {
      removingWidgetInProgress.value.push(widgetUuid)
    })

    socket.value.on('WIDGET_REMOVED', (eventData) => {
      store.commit(Mutations.REMOVE_WIDGET_FROM_COPY, eventData.content.widgetUuid)
      const index = removingWidgetInProgress.value.indexOf(eventData.content.widgetUuid)
      if (index > -1) { // only splice array when item is found
        removingWidgetInProgress.value.splice(index, 1) // 2nd parameter means remove one item only
      }
    })

    const persistWidget = () => {
      console.log('Persisting widget...')
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidget.value
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const onWidgetBackgroundColorChanged = (color) => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      currentWidget.value.specific.color = color
      persistWidget()
    }

    const onDiscoveryClicked = () => {
      if (currentWidget.value.specific.hotspot) {
        currentWidget.value.specific.hotspot = false
      }
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      currentWidget.value.discovery = !currentWidget.value.discovery
      persistWidget()
    }

    const onDraggableClicked = (widgetIndex) => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, widgetIndex)
      currentWidget.value.inGameSettings.isDraggable = !currentWidget.value.inGameSettings.isDraggable
      persistWidget()
    }
    const onResizableClicked = (widgetIndex) => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, widgetIndex)
      currentWidget.value.inGameSettings.isResizable = !currentWidget.value.inGameSettings.isResizable
      persistWidget()
    }
    const onMobileVisibleClicked = () => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      if (currentWidget.value.category === 'action') return // only info widgets can be hidden from mobile
      // next ternary is needed for the widgets that were added before the feature and don't have the mobileVisible property
      currentWidget.value.mobileVisible = currentWidget.value.mobileVisible === undefined ? false : !currentWidget.value.mobileVisible
      persistWidget()
    }

    const onUploadFileClicked = (widgetUuid) => {
      emitter.emit('upload-file-clicked', widgetUuid)
    }

    const widgetColors = ref([]) as any

    watch(currentWidget, () => {
      widgetColors.value = computeWidgetColorsByType(currentWidget)
    })
    widgetColors.value = computeWidgetColorsByType(currentWidget)

    const colorWidgets = [
      CODE,
      DRAG_AND_DROP_GROUP,
      DRAG_AND_DROP_ORDER,
      FILE_UPLOAD,
      FILE_UPLOAD_STYLED,
      FLASHCARD,
      HEADING,
      IMAGE_CARD,
      VIDEO_EMBEDDED,
      TEXT_CARD,
      TEXT_CARD_STYLED,
      SHAPE,
      QUESTION_SINGLE_CHOICE,
      QUESTION_TEAM_READINESS,
      QUESTION_MULTIPLE_CHOICE,
      QUESTION_SHORT_ANSWER,
      QUESTION_FREE_ANSWER,
      QUESTION_SHORT_ANSWER_AI_SCORED,
      REFLECTION_POST_IT
    ]

    const onBringToFrontClicked = () => {
      store.commit(Mutations.SET_TEMPLATE_WIDGET_ORDER_CHANGED, { widgetIndex: props.widgetIndex, toFront: true })
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      const activeSectionWidgets = computed(() => store.getters.getActiveSectionWidgets)

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: locatorInstance,
        widgets: activeSectionWidgets.value
      }
      socket.value.emit('EDIT_LAYOUT_ORDER_SECTION', {
        eventType: 'EDIT_LAYOUT_ORDER_SECTION',
        payload: socketPayload
      })
    }

    const onBringToBackClicked = () => {
      store.commit(Mutations.SET_TEMPLATE_WIDGET_ORDER_CHANGED, { widgetIndex: props.widgetIndex, toFront: false })
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      const activeSectionWidgets = computed(() => store.getters.getActiveSectionWidgets)

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: locatorInstance,
        widgets: activeSectionWidgets.value
      }
      socket.value.emit('EDIT_LAYOUT_ORDER_SECTION', {
        eventType: 'EDIT_LAYOUT_ORDER_SECTION',
        payload: socketPayload
      })
    }

    const onDuplicateClicked = () => {
      const activeWidget = computed(() => store.getters.getActiveWidget)
      const duplicatedWidget = { ...activeWidget.value } as Widget
      const duplicatedWidgetUuid = uuid.v4()
      duplicatedWidget.widgetUuid = duplicatedWidgetUuid
      duplicatedWidget.position.i = duplicatedWidgetUuid

      const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: {
          activeSectionIndex: activeSectionIndex.value
        },
        widget: duplicatedWidget
      }
      socket.value.emit('COPY_WIDGET', {
        eventType: 'COPY_WIDGET',
        payload: socketPayload
      })
    }

    const onRoundedClicked = () => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      currentWidget.value.rounded = !currentWidget.value.rounded
      persistWidget()
    }

    const onBorderedClicked = () => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      currentWidget.value.border = !currentWidget.value.border
      persistWidget()
    }

    const onTransformToHotSpotClicked = (widgetUuid, hotspotPosition) => {
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
      if (
        currentWidget.value.type !== 'text-card-styled' &&
        currentWidget.value.type !== 'file-upload' &&
        currentWidget.value.type !== 'image-card' &&
        currentWidget.value.type !== 'video-embedded' &&
        currentWidget.value.type !== 'code' &&
        currentWidget.value.type !== 'question-short-answer' &&
        currentWidget.value.type !== 'question-short-answer-ai-scored' &&
        currentWidget.value.type !== 'question-single-choice' &&
        currentWidget.value.type !== 'question-team-readiness' &&
        currentWidget.value.type !== 'question-multiple-choice' &&
        currentWidget.value.type !== 'flashcard'
      ) return
      if (hotspotPosition === null) {
        currentWidget.value.specific.hotspot = false
        currentWidget.value.specific.hotspotPosition = null
      } else {
        store.commit(Mutations.ADD_HOTSPOT_EXPANDED_WIDGETS, widgetUuid)
        currentWidget.value.specific.hotspot = true
        currentWidget.value.specific.hotspotPosition = hotspotPosition
        currentWidget.value.discovery = false
      }
      persistWidget()
    }

    const actionWidgets = actionWidgetTypes

    return {
      colorMap,
      colorWidgets,
      actionWidgets,
      widgetColors,
      removingWidgetInProgress,
      activityTemplate,
      getActiveWidgets,
      groupedWidgets,
      mobileViewMode,
      revealMobileHiddenWidgets,
      onRemoveWidgetClicked,
      onWidgetBackgroundColorChanged,
      onDiscoveryClicked,
      onDraggableClicked,
      onResizableClicked,
      onBringToFrontClicked,
      onBringToBackClicked,
      onUploadFileClicked,
      onDuplicateClicked,
      onRoundedClicked,
      onBorderedClicked,
      onTransformToHotSpotClicked,
      onMobileVisibleClicked,
      handleCommandImage360,
      scenesFor360Image,
      selectedWidgetScene,
      onPositionChanged,
      onRegenerateClicked,
      CirclePlusFilled,
      PictureFilled
    }
  }
})
