import { Section } from '@/core/helpers/template/TemplateInterface'
import { uuid } from 'vue-uuid'

export const generateIntroSection = (): Section => {
  const sectionUuid = uuid.v4()
  return {
    sectionUuid: sectionUuid,
    title: 'Intro',
    color: 'Default',
    backgroundImage: null,
    widgets: [
      {
        widgetUuid: '37d56c1a-0965-4687-ad38-fc88342d9c22',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'orange',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '37d56c1a-0965-4687-ad38-fc88342d9c22',
          x: 0,
          y: 0,
          w: 211,
          h: 270
        }
      },
      {
        widgetUuid: '431d1035-6522-4452-b603-8f6dd61289eb',
        type: 'image-card',
        category: 'info',
        discovery: false,
        rounded: true,
        border: false,
        specific: {
          image: null,
          color: 'dark',
          hotspot: false,
          hotspotPosition: ''
        },
        minWidth: 15,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '431d1035-6522-4452-b603-8f6dd61289eb',
          x: 73,
          y: 107,
          w: 57,
          h: 54
        }
      },
      {
        widgetUuid: '1d5b3513-ccbb-4281-8d61-daf6138f9f91',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '<p><strong><span style="font-size: 48pt; font-family: roboto, sans-serif;">Creative course </span></strong></p>\n<p><strong><span style="font-family: roboto, sans-serif;"><span style="font-size: 48pt;">title </span><span style="font-size: 48pt;">goes here</span></span></strong></p>',
          color: 'transparent',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '1d5b3513-ccbb-4281-8d61-daf6138f9f91',
          x: 255,
          y: 68,
          w: 159,
          h: 52
        }
      },
      {
        widgetUuid: '5fafa1e6-acc9-458f-a551-eab12a034f36',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'green',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '5fafa1e6-acc9-458f-a551-eab12a034f36',
          x: 261,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'e09e2db4-cbd7-4e4f-96e3-0f4e3f2dc931',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'orange',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'e09e2db4-cbd7-4e4f-96e3-0f4e3f2dc931',
          x: 277,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'e78212a8-b45e-4a88-876a-43c40539c346',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'purple',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'e78212a8-b45e-4a88-876a-43c40539c346',
          x: 293,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'da7326b4-b09d-416a-94b7-563b8dc327dc',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'blue',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'da7326b4-b09d-416a-94b7-563b8dc327dc',
          x: 309,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'e07fa2d8-5d50-4e41-a68a-d24d37b88470',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'red-dark',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'e07fa2d8-5d50-4e41-a68a-d24d37b88470',
          x: 326,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'e5f364a1-11f4-4297-a439-b616b97efee8',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'yellow',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'e5f364a1-11f4-4297-a439-b616b97efee8',
          x: 343,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: 'e44b3940-a79d-41e6-ae63-1cbcd813a5e7',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '',
          color: 'navy-blue',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: 'e44b3940-a79d-41e6-ae63-1cbcd813a5e7',
          x: 359,
          y: 129,
          w: 10,
          h: 10
        }
      },
      {
        widgetUuid: '16cb7161-c7c2-4799-8d01-2c909bb8bdeb',
        type: 'text-card-styled',
        category: 'info',
        discovery: false,
        rounded: false,
        border: false,
        specific: {
          content: '<p><span style="font-size: 26pt; font-family: roboto, sans-serif;">A short description of the course, a welcome message or a subtitle goes here</span></p>',
          color: 'transparent',
          hotspot: false,
          hotspotPosition: null
        },
        minWidth: 10,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '16cb7161-c7c2-4799-8d01-2c909bb8bdeb',
          x: 255,
          y: 152,
          w: 159,
          h: 57
        }
      },
      {
        widgetUuid: '16073b2e-c29d-4fa5-aed8-bff45344f70f',
        type: 'image-card',
        category: 'info',
        discovery: false,
        rounded: true,
        border: false,
        specific: {
          image: null,
          color: 'dark',
          hotspot: false,
          hotspotPosition: ''
        },
        minWidth: 15,
        minHeight: 10,
        maxWidth: 0,
        maxHeight: 0,
        mobileVisible: true,
        position: {
          i: '16073b2e-c29d-4fa5-aed8-bff45344f70f',
          x: 448,
          y: 234,
          w: 20,
          h: 21
        }
      }
    ],
    widgetsMobilePosition: [
      {
        widgetUuid: '37d56c1a-0965-4687-ad38-fc88342d9c22'
      },
      {
        widgetUuid: '431d1035-6522-4452-b603-8f6dd61289eb'
      },
      {
        widgetUuid: '1d5b3513-ccbb-4281-8d61-daf6138f9f91'
      },
      {
        widgetUuid: '5fafa1e6-acc9-458f-a551-eab12a034f36'
      },
      {
        widgetUuid: 'e09e2db4-cbd7-4e4f-96e3-0f4e3f2dc931'
      },
      {
        widgetUuid: 'e78212a8-b45e-4a88-876a-43c40539c346'
      },
      {
        widgetUuid: 'da7326b4-b09d-416a-94b7-563b8dc327dc'
      },
      {
        widgetUuid: 'e07fa2d8-5d50-4e41-a68a-d24d37b88470'
      },
      {
        widgetUuid: 'e5f364a1-11f4-4297-a439-b616b97efee8'
      },
      {
        widgetUuid: 'e44b3940-a79d-41e6-ae63-1cbcd813a5e7'
      },
      {
        widgetUuid: '16cb7161-c7c2-4799-8d01-2c909bb8bdeb'
      },
      {
        widgetUuid: '16073b2e-c29d-4fa5-aed8-bff45344f70f'
      }
    ],
    settings: {
      enabled: true,
      locked: false,
      lockedPassword: '',
      lockedPasswordHint: ''
    }
  }
}
