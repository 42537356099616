import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04139ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "builder-controls-menu-container" }
const _hoisted_2 = { class: "builder-controls-menu" }
const _hoisted_3 = {
  class: "btn btn-sm btn-icon control-button previous-section",
  id: "kt_activities_toggle_previous_section"
}
const _hoisted_4 = { class: "svg-icon" }
const _hoisted_5 = { class: "control-button-radar" }
const _hoisted_6 = {
  class: "btn btn-sm btn-icon control-button",
  "data-bs-toggle": "modal",
  "data-bs-target": "#the_edit_activity_modal"
}
const _hoisted_7 = { class: "svg-icon" }
const _hoisted_8 = { class: "svg-icon" }
const _hoisted_9 = { class: "main-action-radar-container" }
const _hoisted_10 = { class: "svg-icon" }
const _hoisted_11 = { class: "svg-icon" }
const _hoisted_12 = {
  class: "btn btn-sm btn-icon control-button next-section",
  style: {"margin-right":"-23px","margin-left":"5px"},
  id: "kt_activities_toggle_next_section"
}
const _hoisted_13 = { class: "svg-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "control-button-radar previous-section",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.previousSectionClick && $setup.previousSectionClick(...args)))
      }, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-arrow-prev.svg" })
          ])
        ]),
        _createTextVNode(" PREV ")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-slider.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["control-button-radar", { active: $setup.readyChecked }])
      }, [
        _createElementVNode("button", {
          class: "btn btn-sm btn-icon control-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onReadyClicked && $setup.onReadyClicked(...args)))
        }, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-check-document.svg" })
          ])
        ])
      ], 2),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          class: _normalizeClass(["main-action-radar", { active: $setup.solvingModeSwitch }])
        }, [
          _createElementVNode("button", {
            class: "btn main-action",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.solvingModeSwitchOnClick && $setup.solvingModeSwitchOnClick(...args)))
          }, "Solving mode")
        ], 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["control-button-radar", { active: $setup.mobileChecked }])
      }, [
        _createElementVNode("button", {
          class: "btn btn-sm btn-icon control-button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.onMobileClicked && $setup.onMobileClicked(...args)))
        }, [
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-smartphone.svg" })
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["control-button-radar mind-stream", { active: $setup.robotModeSwitch }])
      }, [
        _createElementVNode("button", {
          class: "btn btn-sm btn-icon control-button",
          id: "kt_activities_toggle_builder",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.robotModeSwitchOnClick && $setup.robotModeSwitchOnClick(...args)))
        }, [
          _createElementVNode("span", _hoisted_11, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-bot.svg" })
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: "control-button-radar next-section",
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.nextSectionClick && $setup.nextSectionClick(...args)))
      }, [
        _createTextVNode(" NEXT "),
        _createElementVNode("button", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, [
            _createVNode(_component_inline_svg, { src: "/mc-admin-media/builder-icons/actions/icons8-arrow-next.svg" })
          ])
        ])
      ])
    ])
  ]))
}