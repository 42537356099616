
import { computed, inject, ref } from 'vue'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'
import { Section } from '@/core/helpers/template/TemplateInterface'
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
  name: 'the-section-background-image-search-modal',
  props: {
    contextClickedSection: { type: Object as () => Section, required: true }
  },
  setup (props) {
    const store = useStore()
    const isModalVisible = ref(false)
    const isModalOpen = ref(false)
    const keywordPhotos = ref('')
    const unsplashPhotos = ref([])
    const emitter = inject('emitter') as any
    const currentAccount = computed(() => store.getters.currentAccount)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)

    emitter.on('open-background-image-modal', () => {
      isModalVisible.value = true
    })

    emitter.on('close-background-image-modal', () => {
      isModalVisible.value = false
    })

    function onModalOpen () {
      isModalOpen.value = true
    }

    function onModalClose () {
      isModalOpen.value = false
      unsplashPhotos.value = []
      keywordPhotos.value = ''
    }

    function onSubmitSearchPhoto () {
      store.dispatch(Actions.API_UNSPLASH_SEARCH_PHOTOS, { page: 0, query: keywordPhotos.value }).then((result) => {
        unsplashPhotos.value = result.payload.photos.results ? result.payload.photos.results : result.payload.photos
      })
    }

    function downloadAndAttachUrl (downloadUrl) {
      store.dispatch(Actions.API_UNSPLASH_FETCH_IMAGE, downloadUrl).then((result) => {
        emitter.emit('section-background-image-changed', result.url)
      })
    }

    const onFileUploaded = (event) => {
      // if (props.readonly) return

      const updateProgressPayload = {
        widgetUuid: props.contextClickedSection.sectionUuid,
        progress: 0
      }

      // todo: file upload progress not implemented correctly here
      store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)

      const data = new FormData()
      data.append('content', event.target.files[0])
      data.append('sectionUuid', props.contextClickedSection.sectionUuid)
      data.append('templateUuid', activityTemplate.value.activityTemplateUuid)

      const config = {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
        onUploadProgress: (progressEvent) => {
          updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        }
      }
      const payload = {
        data: data,
        config: config
      }

      store.dispatch(Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        const updateSectionPayload = {
          sectionUuid: props.contextClickedSection.sectionUuid,
          attachmentMeta: response.payload.meta
        } as any
        store.commit(Mutations.UPDATE_SECTION_SET_IMAGE, updateSectionPayload)
      }).catch((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    return {
      isModalVisible,
      isModalOpen,
      keywordPhotos,
      unsplashPhotos,
      onModalOpen,
      onModalClose,
      onSubmitSearchPhoto,
      downloadAndAttachUrl,
      onFileUploaded,
      currentAccount,
      buildTemplateFileUri
    }
  }
}
