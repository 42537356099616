import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b0ba624"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 3 }
const _hoisted_3 = {
  class: "accordion mobile-sections-accordion",
  id: "mobile-sections-accordion"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["data-bs-target", "aria-controls", "onClick"]
const _hoisted_7 = { class: "accordion-counter" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = { class: "accordion-body" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BuilderWidgetMenu = _resolveComponent("BuilderWidgetMenu")!
  const _component_BuilderSectionMenu = _resolveComponent("BuilderSectionMenu")!
  const _component_BuilderJitsiMenu = _resolveComponent("BuilderJitsiMenu")!
  const _component_BuilderWidget = _resolveComponent("BuilderWidget")!
  const _component_grid_item = _resolveComponent("grid-item")!
  const _component_grid_layout = _resolveComponent("grid-layout")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_BuilderControlsMenu = _resolveComponent("BuilderControlsMenu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "builderWorkspaceWrapper",
    class: _normalizeClass(["workspace-wrapper", { 'mobile': _ctx.mobileViewMode }]),
    id: "builder-workspace-wrapper"
  }, [
    _createElementVNode("div", {
      ref: "builderWorkspace",
      class: _normalizeClass(["builder-workspace", { mobile: _ctx.mobileViewMode, 'grid-visible': _ctx.showGridLines }]),
      id: "builder-workspace",
      onMousemove: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseMove && _ctx.onMouseMove(...args))),
      style: _normalizeStyle(_ctx.sectionBackgroundStyle)
    }, [
      _createVNode(_component_BuilderWidgetMenu, {
        onWidgetDragend: _ctx.widgetDragend,
        onWidgetDrag: _ctx.widgetDrag
      }, null, 8, ["onWidgetDragend", "onWidgetDrag"]),
      (!_ctx.mobileViewMode)
        ? (_openBlock(), _createBlock(_component_BuilderSectionMenu, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.mobileViewMode)
        ? (_openBlock(), _createBlock(_component_BuilderJitsiMenu, {
            key: 1,
            isHovered: _ctx.isMouseHoveringOverJitsi
          }, null, 8, ["isHovered"]))
        : _createCommentVNode("", true),
      (!_ctx.mobileViewMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.section)
              ? (_openBlock(), _createBlock(_component_grid_layout, {
                  key: 0,
                  ref: "gridLayout",
                  layout: _ctx.activeSectionWidgetsLayout,
                  "auto-size": false,
                  "prevent-collision": true,
                  "col-num": _ctx.builderColNum,
                  "max-rows": _ctx.builderRowNum,
                  "row-height": _ctx.builderRowHeight,
                  "vertical-compact": false,
                  margin: [_ctx.builderItemMarginX, _ctx.builderItemMarginY],
                  "use-css-transforms": false,
                  onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                  "is-draggable": _ctx.activityTemplate.usedCounter === 0,
                  "is-resizable": _ctx.activityTemplate.usedCounter === 0,
                  "transform-scale": _ctx.scale,
                  onLayoutReady: _ctx.layoutReady
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSectionWidgets, (widget) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_grid_item, {
                        ref_for: true,
                        ref: "setItemRef",
                        x: widget.position.x,
                        y: widget.position.y,
                        w: widget.position.w,
                        h: widget.position.h,
                        i: widget.widgetUuid,
                        key: widget.widgetUuid,
                        minW: !widget.minWidth ? 1 : widget.minWidth ,
                        minH: !widget.minHeight ? 1 : widget.minHeight,
                        maxW: !widget.maxWidth ? Infinity : widget.maxWidth,
                        maxH: !widget.maxHeight ? Infinity : widget.maxHeight,
                        "drag-ignore-from": ".no-drag",
                        preserveAspectRatio: widget.type === 'icon-card' || (widget.type === 'shape' && widget.specific.type === 'circle'),
                        onMove: _ctx.widgetMove,
                        onMoved: _ctx.widgetMoved,
                        onResized: _ctx.widgetResized,
                        onResize: _ctx.widgetResize,
                        "is-bounded": false,
                        class: _normalizeClass([
                             _ctx.isItemMoving.includes(widget.widgetUuid) ? 'transparency' : '',
                     widget.widgetUuid === 'dummy' ? 'dummy-widget' : '',
                     _ctx.newlyAddedWidgets.indexOf(widget.widgetUuid) !== -1 ? 'drop-down': '',
                     widget.specific.color === 'dark' ? 'is-dark': '',
                     ('color' in widget.specific) > 0 ? _ctx.colorClassMap[widget.specific.color] : '',
                     widget.specific.hotspot ? 'hotspot-widget' : '',
                     widget.specific.hotspot ? _ctx.hotspotPositionClassMap[widget.specific.hotspotPosition] : '',
                     { 'active-widget': _ctx.getActiveWidgets.includes(widget.widgetUuid) && !_ctx.groupedWidgets.some(gw => gw.widgetUuid === widget.widgetUuid) },
                     { 'hotspot-expanded-widget': _ctx.getExpandedHotspotWidgets.includes(widget.widgetUuid) },
                   ]),
                        onClick: ($event: any) => {_ctx.focusWidget(widget.widgetUuid); _ctx.handleMultipleSelect($event, widget.widgetUuid)}
                      }, {
                        default: _withCtx(() => [
                          (widget.widgetUuid)
                            ? (_openBlock(), _createBlock(_component_BuilderWidget, {
                                key: 0,
                                "widget-uuid": widget.widgetUuid,
                                readonly: _ctx.activityTemplate.usedCounter > 0,
                                onOpenWidgetSettings: ($event: any) => (_ctx.$emit('open-widget-settings', widget.widgetUuid)),
                                onWidgetDelete: ($event: any) => (_ctx.$emit('widget-delete', widget.widgetUuid))
                              }, null, 8, ["widget-uuid", "readonly", "onOpenWidgetSettings", "onWidgetDelete"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["x", "y", "w", "h", "i", "minW", "minH", "maxW", "maxH", "preserveAspectRatio", "onMove", "onMoved", "onResized", "onResize", "class", "onClick"])), [
                        [_directive_click_outside, () => _ctx.dropFocus(widget.widgetUuid)]
                      ])
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["layout", "col-num", "max-rows", "row-height", "margin", "is-draggable", "is-resizable", "transform-scale", "onLayoutReady"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, sectionIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: section,
                  class: _normalizeClass(["accordion-item", { 'active': sectionIndex === _ctx.activeSectionIndex }]),
                  onClick: ($event: any) => (_ctx.sectionChange(sectionIndex))
                }, [
                  _createElementVNode("h2", {
                    class: "accordion-header",
                    id: `${'section_header_'+ section.sectionUuid }`
                  }, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["accordion-button", { 'collapsed': sectionIndex !== 0 }]),
                      type: "button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": `${'#section_body_'+ section.sectionUuid }`,
                      "aria-expanded": "true",
                      "aria-controls": `${'section_body_'+ section.sectionUuid }`,
                      onClick: ($event: any) => (_ctx.scrollInPosition($event, sectionIndex))
                    }, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(sectionIndex + 1), 1),
                      _createElementVNode("div", {
                        class: "section-title",
                        innerHTML: section.title
                      }, null, 8, _hoisted_8)
                    ], 10, _hoisted_6)
                  ], 8, _hoisted_5),
                  _createElementVNode("div", {
                    id: `${'section_body_'+ section.sectionUuid }`,
                    class: _normalizeClass(["accordion-collapse collapse", { 'show': sectionIndex === 0 }]),
                    "aria-labelledby": `${'section_header_'+ section.sectionUuid }`,
                    "data-bs-parent": "#mobile-sections-accordion"
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_draggable, _mergeProps({
                        class: "section-widget-list",
                        "component-data": { name: !_ctx.dragStarted ? 'flip-list' : null },
                        list: section.widgetsMobilePosition,
                        group: "available-widgets",
                        "item-key": "widgetUuid"
                      }, { animation: 300, ghostClass: 'ghost-widget' }, {
                        onEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onListWidgetDragged($event, _ctx.activeSectionWidgetsMobilePosition))),
                        onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dragStarted = true))
                      }), {
                        item: _withCtx(({element}) => [
                          _withDirectives((_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(["widget-mobile", [{ 'active-widget': _ctx.getActiveWidgets.includes(element.widgetUuid) && !_ctx.groupedWidgets.some(gw => gw.widgetUuid === element.widgetUuid) }]]),
                            onClick: ($event: any) => {_ctx.focusWidget(element.widgetUuid); _ctx.handleMultipleSelect($event, element.widgetUuid)}
                          }, [
                            _createVNode(_component_BuilderWidget, {
                              "widget-uuid": element.widgetUuid,
                              readonly: _ctx.activityTemplate.usedCounter > 0,
                              onOpenWidgetSettings: ($event: any) => (_ctx.$emit('open-widget-settings', element.widgetUuid)),
                              onWidgetDelete: ($event: any) => (_ctx.$emit('widget-delete', element.widgetUuid))
                            }, null, 8, ["widget-uuid", "readonly", "onOpenWidgetSettings", "onWidgetDelete"])
                          ], 10, _hoisted_11)), [
                            [_directive_click_outside, () => _ctx.dropFocus(element.widgetUuid)]
                          ])
                        ]),
                        _: 2
                      }, 1040, ["component-data", "list"])
                    ])
                  ], 10, _hoisted_9)
                ], 10, _hoisted_4))
              }), 128))
            ])
          ])),
      (!_ctx.mobileViewMode)
        ? (_openBlock(), _createBlock(_component_BuilderControlsMenu, {
            key: 4,
            template: _ctx.activityTemplate
          }, null, 8, ["template"]))
        : _createCommentVNode("", true)
    ], 38)
  ], 2))
}