
import { computed, defineComponent } from 'vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { QuestionShortAnswer } from '@/core/helpers/template/WidgetInterface'
import Editor from '@tinymce/tinymce-vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'question-short-answer',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionShortAnswer, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      widgetFound,
      editorSettings,
      solvingMode,
      getApiKey,
      persistWidget
    }
  }
})
