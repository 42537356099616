
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { VideoEmbeddedStyled } from '@/core/helpers/template/WidgetInterface'
import { ElNotification } from 'element-plus/es'

export default defineComponent({
  name: 'video-embedded-styled',
  props: {
    widget: { type: Object as () => VideoEmbeddedStyled, required: true },
    readonly: { type: Boolean, default: false }
  },
  setup: function (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    const url = ref(widgetFound.value.specific.url)
    const urlIsInvalid = ref(false)
    const videoIsDisplayed = ref(widgetFound.value.specific.url.length > 0)

    const onUrlChanged = () => {
      console.log('Url changed...')
      url.value = url.value.trim()

      if (urlWasTransformed.value && transformedUrl.value !== null) {
        url.value = transformedUrl.value
        transformedUrl.value = null
        urlWasTransformed.value = false
      }
      validateYouTubeUrl()
    }

    const urlWasTransformed = ref(false)
    const transformedUrl = ref(null) as any
    const onUrlPasted = (evt) => {
      console.log('Url pasted...')
      const pastedValue = evt.clipboardData.getData('text')
      const embedUrlBase = 'https://www.youtube.com/embed/'

      if (!pastedValue.includes('embed') && (pastedValue.includes('watch?v=') || pastedValue.includes('youtu.be/'))) {
        let videoId = ''
        if (pastedValue.includes('watch?v=')) {
          videoId = pastedValue.split('watch?v=')[1].substring(0, 11)
        }
        if (pastedValue.includes('youtu.be/')) {
          videoId = pastedValue.split('youtu.be/')[1].substring(0, 11)
        }
        urlWasTransformed.value = true
        transformedUrl.value = embedUrlBase + videoId
      }
    }

    const validateYouTubeUrl = () => {
      console.log('Validating url...')
      if (url.value === '') {
        urlIsInvalid.value = false
      } else {
        const regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/
        const match = url.value.match(regExp)
        urlIsInvalid.value = !match
      }
    }

    const onDisplayUrlInput = () => {
      console.log('Display edit clicked...')
      videoIsDisplayed.value = !videoIsDisplayed.value
    }

    const onEmbedClicked = () => {
      console.log('Embedded clicked, trying to persist.')
      if (!urlIsInvalid.value) {
        if (url.value.length > 0) {
          videoIsDisplayed.value = true
        }
        widgetFound.value.specific.url = url.value
        persistWidget()
      } else {
        ElNotification.warning({
          message: 'The <strong>URL</strong> is invalid and could not be embedded.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }
    }

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      editorSettings,
      widgetFound,
      solvingMode,
      url,
      urlIsInvalid,
      videoIsDisplayed,
      getApiKey,
      persistWidget,
      onUrlChanged,
      onUrlPasted,
      onEmbedClicked,
      onDisplayUrlInput
    }
  }
})
