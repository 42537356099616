
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import Editor from '@tinymce/tinymce-vue'
import { Heading } from '@/core/helpers/template/WidgetInterface'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'heading',
  props: {
    widget: { type: Object as () => Heading, required: true },
    readonly: { type: Boolean, default: false },
    resizeInfo: { type: Object }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    const isDisabled = ref(false)

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: widgetFound.value
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const editorAdvancedSettings = {
      setup: (editor) => {
        editor.ui.registry.addContextToolbar('textselection', {
          predicate: () => !editor.selection.isCollapsed(),
          items: ' alignleft aligncenter alignright alignjustify forecolor backcolor | italic underline strikethrough fontselect fontsizeselect',
          position: 'selection',
          scope: 'node'
        })
        editor.on('init', function () {
          // move cursor to end of text
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('NodeChange', () => { // fixes an issue with editor not focusing when it becomes enabled
          if (!editor.getContainer().classList.contains('mce-disabled')) {
            editor.focus()
          }
        })
        editor.on('Focus', () => { // move cursor at the end when focusing
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          persistWidget()
        })
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      plugins: ['lists', 'paste'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',
      font_formats: 'Alegreya=alegreya,serif; Lato=lato,sans-serif; Merriweather=merriweather,serif; Montserrat=montserrat,serif; Open Sans=open sans,sans-serif; Oswald=oswald,sans-serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif; Roboto=roboto,sans-serif;Times New Roman=times new roman,times',
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    const vcoConfig = {
      handler: () => {
        isDisabled.value = true
      },
      middleware: (e) => {
        return (e.target && typeof e.target.className === 'string' && !e.target.className.match(/tox/))
      },
      events: ['dblclick', 'click'],
      isActive: true,
      detectIFrame: true,
      capture: false
    }

    onMounted(() => {
      setTimeout(() => {
        isDisabled.value = true // this is needed to fix a bug for tiny mce not showing placeholder if is initialized as disabled
      }, 500)
    })

    return {
      widgetFound,
      editorAdvancedSettings,
      isDisabled,
      vcoConfig,
      persistWidget,
      getApiKey
    }
  }
})
