
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { menuInfoWidgets, menuActionWidgets } from '@/core/helpers/template/widgetMenu'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'builder-widget-menu',
  props: {
    iconfinderWasClicked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['widget-drag', 'widget-dragend', 'show-iconfinder'],
  setup (props, { emit }) {
    const store = useStore()
    const infoWidgets = ref(menuInfoWidgets)
    const actionWidgets = ref(menuActionWidgets)
    const searchInput = ref('')
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const showTooltip = ref(true)
    const onWidgetsSearch = () => {
      infoWidgets.value = menuInfoWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
      actionWidgets.value = menuActionWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
    }
    const clickedIconfinder = ref(props.iconfinderWasClicked)
    const onDragStart = (event) => {
      // needed to hide the green round plus cursor and the "globe" in chrome when dragging
      event.dataTransfer.dropEffect = 'none'
      event.dataTransfer.effectAllowed = 'move'
      const blankCanvas: any = document.createElement('canvas')
      blankCanvas.classList.add('hide-cursor-when-dragging')
      blankCanvas.style.width = '1px'
      blankCanvas.style.height = '1px'
      event.dataTransfer?.setDragImage(blankCanvas, 0, 0)
      document.body?.getElementsByClassName('builder-workspace')[0].appendChild(blankCanvas)
    }
    const onDragEnd = (event) => {
      // needed to hide the green round plus cursor and the "globe" in chrome when dragging
      event.dataTransfer.dropEffect = 'none'
      event.dataTransfer.effectAllowed = 'move'
      const blankCanvas = document.getElementsByClassName('hide-cursor-when-dragging')[0]
      if (blankCanvas) {
        document.body?.getElementsByClassName('builder-workspace')[0].removeChild(blankCanvas)
      }
    }

    const emitter = inject('emitter') as any
    const onClickHandler = (menuItem) => {
      // no click for specific widgets
      if (menuItem.type !== 'iconfinder') {
        return
      }
      clickedIconfinder.value = !clickedIconfinder.value
      emitter.emit('show-iconfinder', clickedIconfinder.value)
    }

    emitter.on('close-iconfinder', () => {
      clickedIconfinder.value = false
    })

    const onDragHandler = (menuItem) => {
      // no drag for specific widgets
      if (menuItem.type === 'iconfinder') {
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Triangle') {
        emit('widget-drag', 'shape-triangle')
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Circle') {
        emit('widget-drag', 'shape-circle')
        return
      }

      emit('widget-drag', menuItem.type)
    }

    const onDragEndHandler = (menuItem) => {
      // no drag for specific widgets
      if (menuItem.type === 'iconfinder') {
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Triangle') {
        emit('widget-dragend', 'shape-triangle')
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Circle') {
        emit('widget-dragend', 'shape-circle')
        return
      }
      emit('widget-dragend', menuItem.type)
    }

    watch(props, (newVal) => {
      clickedIconfinder.value = newVal.iconfinderWasClicked
    })

    return {
      searchInput,
      infoWidgets,
      actionWidgets,
      activityTemplate,
      clickedIconfinder,
      showTooltip,
      onWidgetsSearch,
      onClickHandler,
      onDragHandler,
      onDragEndHandler,
      onDragStart,
      onDragEnd
    }
  }
})
