
import { defineComponent, ref, PropType } from 'vue'

interface VideoEmbeddedStyled {
    specific: {
        url: string;
    };
}

interface UrlContainerProps {
    widget: VideoEmbeddedStyled;
    readonly: boolean;
}

export default defineComponent({
  name: 'UrlContainer',
  props: {
    widget: {
      type: Object as PropType<VideoEmbeddedStyled>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props: UrlContainerProps, { emit }) {
    const url = ref(props.widget.specific.url)
    const urlIsInvalid = ref(false)

    const onUrlChanged = () => {
      url.value = url.value.trim()
      validateUrl()
    }

    const onUrlPasted = (evt: ClipboardEvent) => {
      // const pastedValue = evt.clipboardData?.getData('text') || ''
      // url.value = pastedValue.trim()
      // validateUrl()
    }

    const onEmbedClicked = () => {
      if (!urlIsInvalid.value) {
        emit('embed-clicked', url.value)
        persistWidget()
      } else {
        // Handle invalid URL case
      }
    }

    const validateUrl = () => {
      const urlPattern = /^https?:\/\/[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/
      urlIsInvalid.value = !urlPattern.test(url.value)
    }

    const persistWidget = () => {
      // Handle widget persistence logic here
    }

    return {
      url,
      urlIsInvalid,
      onUrlChanged,
      onUrlPasted,
      onEmbedClicked
    }
  }
})
