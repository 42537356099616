import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "widget-header" }
const _hoisted_2 = { class: "widget-header-content" }
const _hoisted_3 = { class: "widget-header-title tiny-container no-drag d-flex" }
const _hoisted_4 = {
  key: 0,
  class: "widget-body-content h-100"
}
const _hoisted_5 = { class: "d-flex d-inline-flex align-items-center w-100 h-100" }
const _hoisted_6 = { class: "w-100 h-100 content-wrapper" }
const _hoisted_7 = {
  key: 1,
  class: "widget-body-content h-100"
}
const _hoisted_8 = { class: "d-flex d-inline-flex align-items-center w-100 h-100" }
const _hoisted_9 = { class: "w-100 h-100 content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_editor, {
            style: {"flex":"0 0 90%"},
            modelValue: _ctx.widgetFound.specific.question,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.widgetFound.specific.question) = $event)),
            "api-key": _ctx.getApiKey(),
            disabled: _ctx.solvingMode || _ctx.readonly,
            init: _ctx.editorSettings,
            "output-format": "text",
            placeholder: "Add question",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.persistWidget()))
          }, null, 8, ["modelValue", "api-key", "disabled", "init"])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["widget-body h-100", { 'no-drag': _ctx.solvingMode }])
    }, [
      (_ctx.solvingMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_editor, {
                  class: "h-100",
                  modelValue: _ctx.widgetFound.specific.solution.answer,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.widgetFound.specific.solution.answer) = $event)),
                  "api-key": _ctx.getApiKey(),
                  disabled: false,
                  init: _ctx.editorAdvancedSettings,
                  onDblclick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDisabled = false)),
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.persistWidget())),
                  onPaste: _cache[5] || (_cache[5] = ($event: any) => (_ctx.persistWidget())),
                  onUndo: _cache[6] || (_cache[6] = ($event: any) => (_ctx.persistWidget()))
                }, null, 8, ["modelValue", "api-key", "init"])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_editor, {
                  "api-key": _ctx.getApiKey(),
                  disabled: _ctx.isDisabled,
                  init: _ctx.editorSettingsSolving,
                  "output-format": "text",
                  placeholder: "Solve question..."
                }, null, 8, ["api-key", "disabled", "init"])
              ])
            ])
          ]))
    ], 2)
  ], 64))
}