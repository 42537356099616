import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-517cabc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-menu-wrapper" }
const _hoisted_2 = { class: "widget-menu-container" }
const _hoisted_3 = { class: "widget-menu-container-inner" }
const _hoisted_4 = { class: "widget-menu" }
const _hoisted_5 = { class: "widget-group" }
const _hoisted_6 = { class: "widget-list" }
const _hoisted_7 = ["draggable", "onClick", "onDrag", "onDragend"]
const _hoisted_8 = { class: "svg-icon" }
const _hoisted_9 = {
  key: 1,
  class: "widget-inner"
}
const _hoisted_10 = { class: "svg-icon" }
const _hoisted_11 = { class: "widgets-list" }
const _hoisted_12 = ["draggable", "onDrag", "onDragend"]
const _hoisted_13 = { class: "widget-inner" }
const _hoisted_14 = { class: "widget-group" }
const _hoisted_15 = { class: "widget-list" }
const _hoisted_16 = ["draggable", "onDrag", "onDragend"]
const _hoisted_17 = {
  key: 0,
  class: "widget-inner"
}
const _hoisted_18 = { class: "svg-icon" }
const _hoisted_19 = {
  key: 1,
  class: "widget-inner"
}
const _hoisted_20 = { class: "svg-icon" }
const _hoisted_21 = { class: "widgets-list" }
const _hoisted_22 = ["draggable", "onDrag", "onDragend"]
const _hoisted_23 = { class: "widget-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoWidgets, (menuItem) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: menuItem,
                  class: "widget",
                  draggable: `${_ctx.activityTemplate.usedCounter === 0 && menuItem.type !== 'iconfinder' ? 'true' : 'false' }`,
                  unselectable: "on",
                  onClick: ($event: any) => (_ctx.onClickHandler(menuItem)),
                  onDragstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDragStart($event))),
                  onDrag: ($event: any) => (_ctx.onDragHandler(menuItem)),
                  onDragend: ($event: any) => {_ctx.onDragEndHandler(menuItem); _ctx.onDragEnd($event)}
                }, [
                  _createVNode(_component_el_tooltip, {
                    content: menuItem.name,
                    placement: "right",
                    effect: "light",
                    "open-delay": 500
                  }, {
                    default: _withCtx(() => [
                      (!menuItem.widgets)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["widget-inner", {'active clicked-widget' : _ctx.clickedIconfinder && menuItem.type === 'iconfinder'}])
                          }, [
                            _createElementVNode("span", _hoisted_8, [
                              _createVNode(_component_inline_svg, {
                                class: _normalizeClass(["img-widget", {'active' : _ctx.clickedIconfinder && menuItem.type === 'iconfinder'}]),
                                src: `${'/mc-admin-media/builder-icons/widgets/' + menuItem.icon}`,
                                alt: ""
                              }, null, 8, ["class", "src"])
                            ])
                          ], 2))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_el_popover, {
                              transition: "el-collapse-transition",
                              "show-arrow": false,
                              onShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTooltip = false)),
                              onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTooltip = true)),
                              placement: "right",
                              width: 200,
                              trigger: "click",
                              "popper-class": "widget-popover"
                            }, {
                              reference: _withCtx(() => [
                                _createElementVNode("span", _hoisted_10, [
                                  _createVNode(_component_inline_svg, {
                                    class: _normalizeClass(["img-widget", {'active' : _ctx.clickedIconfinder && menuItem.type === 'iconfinder'}]),
                                    src: `${'/mc-admin-media/builder-icons/widgets/' + menuItem.icon}`,
                                    alt: ""
                                  }, null, 8, ["class", "src"])
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_11, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.widgets, (widget) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: widget,
                                      class: _normalizeClass(['widget', widget.type + '-icon']),
                                      draggable: `${_ctx.activityTemplate.usedCounter === 0 ? 'true' : 'false' }`,
                                      unselectable: "on",
                                      onDrag: ($event: any) => (_ctx.onDragHandler(widget)),
                                      onDragend: ($event: any) => {_ctx.onDragEndHandler(widget); _ctx.onDragEnd($event)}
                                    }, [
                                      _createVNode(_component_el_tooltip, {
                                        content: widget.name,
                                        placement: "right",
                                        effect: "light",
                                        "open-delay": 0
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_13, [
                                            _createVNode(_component_inline_svg, {
                                              class: "img-widget",
                                              src: `${'/mc-admin-media/builder-icons/widgets/' + widget.icon}`,
                                              alt: ""
                                            }, null, 8, ["src"])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["content"])
                                    ], 42, _hoisted_12))
                                  }), 128))
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ], 40, _hoisted_7))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionWidgets, (menuItem) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: menuItem,
                  class: _normalizeClass(['widget', menuItem.type + '-icon']),
                  draggable: `${_ctx.activityTemplate.usedCounter === 0 ? 'true' : 'false' }`,
                  unselectable: "on",
                  onDragstart: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDragStart($event))),
                  onDrag: ($event: any) => (_ctx.$emit('widget-drag', menuItem.type)),
                  onDragend: ($event: any) => {_ctx.$emit('widget-dragend', menuItem.type); _ctx.onDragEnd($event)}
                }, [
                  _createVNode(_component_el_tooltip, {
                    content: menuItem.name,
                    placement: "right",
                    effect: "light",
                    "open-delay": 0,
                    disabled: !_ctx.showTooltip && menuItem.type === 'question-short-answer'
                  }, {
                    default: _withCtx(() => [
                      (!menuItem.widgets)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, [
                              _createVNode(_component_inline_svg, {
                                class: _normalizeClass(["img-widget", {'active' : _ctx.clickedIconfinder && menuItem.type === 'iconfinder'}]),
                                src: `${'/mc-admin-media/builder-icons/widgets/' + menuItem.icon}`,
                                alt: ""
                              }, null, 8, ["class", "src"])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createVNode(_component_el_popover, {
                              transition: "el-collapse-transition",
                              "show-arrow": false,
                              onShow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTooltip = false)),
                              onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showTooltip = true)),
                              placement: "right",
                              width: 200,
                              trigger: "click",
                              "popper-class": "widget-popover"
                            }, {
                              reference: _withCtx(() => [
                                _createElementVNode("span", _hoisted_20, [
                                  _createVNode(_component_inline_svg, {
                                    class: _normalizeClass(["img-widget", {'active' : _ctx.clickedIconfinder && menuItem.type === 'iconfinder'}]),
                                    src: `${'/mc-admin-media/builder-icons/widgets/' + menuItem.icon}`,
                                    alt: ""
                                  }, null, 8, ["class", "src"])
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_21, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.widgets, (widget) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: widget,
                                      class: _normalizeClass(['widget', widget.type + '-icon']),
                                      draggable: `${_ctx.activityTemplate.usedCounter === 0 ? 'true' : 'false' }`,
                                      unselectable: "on",
                                      onDrag: ($event: any) => (_ctx.$emit('widget-drag', widget.type)),
                                      onDragend: ($event: any) => (_ctx.$emit('widget-dragend', widget.type))
                                    }, [
                                      _createVNode(_component_el_tooltip, {
                                        content: widget.name,
                                        placement: "right",
                                        effect: "light",
                                        "open-delay": 0
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_23, [
                                            _createVNode(_component_inline_svg, {
                                              class: "img-widget",
                                              src: `${'/mc-admin-media/builder-icons/widgets/' + widget.icon}`,
                                              alt: ""
                                            }, null, 8, ["src"])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["content"])
                                    ], 42, _hoisted_22))
                                  }), 128))
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                    ]),
                    _: 2
                  }, 1032, ["content", "disabled"])
                ], 42, _hoisted_16))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}