
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { FileUpload } from '@/core/helpers/template/WidgetInterface.ts'
import { Actions, Mutations } from '@/store/enums/StoreEnums.ts'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default defineComponent({
  name: 'image-card',
  props: {
    widget: { type: Object as () => FileUpload, required: true },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const currentAccount = computed(() => store.getters.currentAccount)
    const fileUpload = ref()
    const uploadFileProgress = (widgetUuid) => computed(() => store.getters.getFileUploadProgressForWidget(widgetUuid))
    const removeFileLoading = ref([]) as any
    const fileMapping: { [key: string]: any } = {
      ppt: {
        icon: 'la-file-powerpoint'
      },
      pptx: {
        icon: 'la-file-powerpoint'
      },
      pdf: {
        icon: 'la-file-pdf'
      },
      doc: {
        icon: 'la-file-word'
      },
      docx: {
        icon: 'la-file-word'
      },
      xls: {
        icon: 'la-file-excel'
      },
      xlsx: {
        icon: 'la-file-excel'
      }
    }
    const emitter = inject('emitter') as any // Inject `emitter`

    const uploadedFilesForWidget = computed(() => store.getters.getUploadedFilesForFileUploadWidget(props.widget.widgetUuid))
    const elements = ref([])
    onMounted(() => {
      emitter.on('upload-file-clicked', (widgetUuid) => {
        const element: any = elements.value.find((element: any) => element.uuid === widgetUuid)
        if (element) {
          element.el.click()
          elements.value = []
        }
      })
    })
    onUnmounted(() => {
      elements.value = []
    })
    const persistWidget = () => {
      console.log('Persisting widget...')
      const currentWidgetInstance = widgetFound.value
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const onFileUploaded = (event) => {
      if (props.readonly) {
        return
      }
      // console.log('name :', event.target.files[0].name)
      // console.log('type :', event.target.files[0].type)
      // console.log('size :', event.target.files[0].size)
      // console.log('last modified :', event.target.files[0].lastModified)
      // console.log('file.value :', event.target.files[0])
      const updateProgressPayload = {
        widgetUuid: props.widget.widgetUuid,
        progress: 0
      }
      store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
      const data = new FormData()
      data.append('content', event.target.files[0])
      data.append('widgetUuid', props.widget.widgetUuid)
      data.append('templateUuid', activityTemplate.value.activityTemplateUuid)

      const config = {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
        onUploadProgress: (progressEvent) => {
          updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        }
      }
      const payload = {
        data: data,
        config: config
      }

      store.dispatch(Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        const updatePayload = {
          widgetUuid: props.widget.widgetUuid,
          attachmentMeta: response.payload.meta
        } as any
        store.commit(Mutations.UPDATE_WIDGET_ADD_FILE, updatePayload)
      }).catch((response) => {
        fileUpload.value.value = null
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    const onFileRemoved = (templateUuid, attachmentUuid) => {
      if (props.readonly) {
        return
      }
      removeFileLoading.value.push(attachmentUuid)
      const payload = {
        templateUuid: templateUuid,
        attachmentUuid: attachmentUuid
      }
      store.dispatch(Actions.API_DELETE_ACTIVITY_TEMPLATE_ATTACHMENT, payload).then((response) => {
        removeFileLoading.value = removeFileLoading.value.filter(e => e !== attachmentUuid)
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
        removeFileLoading.value = removeFileLoading.value.filter(e => e !== attachmentUuid)
      })
    }

    const onFileDownload = (fileUuid) => {
      console.log('Downloading file:', fileUuid)
    }

    return {
      solvingMode,
      uploadedFilesForWidget,
      fileMapping,
      activityTemplate,
      fileUpload,
      removeFileLoading,
      currentAccount,
      elements,
      onFileRemoved,
      onFileDownload,
      persistWidget,
      onFileUploaded,
      buildTemplateFileUri,
      uploadFileProgress
    }
  }
})
