
import { defineComponent, ref, PropType, ComputedRef, computed, inject, reactive } from 'vue'
interface IframeWidget {
    widgetUuid,
    specific: {
        url: string;
    };
}
interface IframeContainerProps {
    widget: IframeWidget;
    readonly: boolean;
}

export default defineComponent({
  name: 'IframeContainer',
  emits: ['edit-clicked'],
  props: {
    widget: {
      type: Object as PropType<IframeWidget>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props: IframeContainerProps, { emit }) {
    const src = ref(props.widget.specific.url)
    const emitter = inject('emitter') as any
    const isDragging = ref(false)
    const canvasRef = ref(null) as any
    const iframeRef = ref(null)
    const capturedImage = ref(null)
    const captureIframe = () => {
      // if someday there will be a working capturing plugin to show that capture while moving / resizing ...
    }
    const captureError = () => {
      console.log('error happened')
    }
    const allowOptions: ComputedRef<string> = computed(() => {
      // Modify this logic to add specific allow options if needed
      // For example, for YouTube videos:
      // return "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";

      // If you don't need any specific allow options, you can return an empty string
      return ''
    })
    const overlayStyle = reactive({
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: 'transparent',
      pointerEvents: 'auto'
    })
    emitter.on('widget-move', (widgetUuid) => {
      if (widgetUuid !== props.widget.widgetUuid) return
      if (!isDragging.value) {
        isDragging.value = true

        // captureIframe()
      }
    })
    emitter.on('widget-moved', (widgetUuid) => {
      if (widgetUuid !== props.widget.widgetUuid) return
      if (isDragging.value) {
        isDragging.value = false
      }
    })

    return {
      src,
      allowOptions,
      canvasRef,
      iframeRef,
      isDragging,
      capturedImage,
      overlayStyle,
      captureIframe,
      captureError
    }
  }
})
