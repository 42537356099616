
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import Editor from '@tinymce/tinymce-vue'
import { StyledTextCard } from '@/core/helpers/template/WidgetInterface'
import vClickOutside from 'click-outside-vue3'

export const styledTextCardBackgroundColors = {
  'green-light': '#BFEDD2',
  green: '#2DC26B',
  'turquoise-dark': '#169179',
  'yellow-light': '#FBEEB8',
  'red-light': '#F8CAC6',
  'purple-light': '#ECCAFA',
  'blue-light': '#C2E0F4',

  yellow: '#F1C40F',
  red: '#E03E2D',
  purple: '#B96AD9',
  blue: '#3598DB',

  orange: '#E67E23',
  'red-dark': '#BA372A',
  'purple-dark': '#843FA1',
  'blue-dark': '#236FA1',
  'grey-light': '#ECF0F1',
  'gray-medium': '#CED4D9',
  gray: '#95A5A6',
  'gray-dark': '#7E8C8D',
  'navy-blue': '#34495E',
  dark: '#1A1C30',
  white: '#FFFFFF',
  transparent: 'transparent'
}

export default defineComponent({
  name: 'text-card-styled',
  props: {
    widget: { type: Object as () => StyledTextCard, required: true },
    readonly: { type: Boolean, default: false },
    resizeInfo: { type: Object }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    editor: Editor
  },
  emits: ['persist-widget'],
  setup (props, { emit }) {
    const dynamicTextValue = computed(() => props.widget.specific.content)
    const textValue = ref('')
    watch(dynamicTextValue, () => {
      textValue.value = dynamicTextValue.value
    })
    const isDisabled = ref(true)
    let persistTimeout = 0 as any
    onMounted(() => {
      widgetFound.value = dynamicWidget.value
      textValue.value = props.widget.specific.content
    })
    const dynamicWidget = computed(() => props.widget)
    watch(dynamicWidget, () => {
      widgetFound.value = dynamicWidget.value
    }, { deep: true })
    const widgetFound = ref({}) as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        widgetFound.value.specific.content = textValue.value
        emit('persist-widget', { widget: widgetFound.value })
      }, 1000)
    }

    const editorAdvancedSettings = {
      setup: (editor) => {
        editor.ui.registry.addContextToolbar('textselection', {
          predicate: () => !editor.selection.isCollapsed(),
          items: 'alignleft aligncenter alignright alignjustify forecolor backcolor numlist bullist link hr | bold italic underline strikethrough fontselect fontsizeselect outdent indent',
          position: 'selection',
          scope: 'node'
        })
        editor.on('init', function () {
          // move cursor to end of text
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('NodeChange', () => { // fixes an issue with editor not focusing when it becomes enabled
          if (!editor.getContainer().classList.contains('mce-disabled')) {
            editor.focus()
          }
        })
        editor.on('Focus', () => { // move cursor at the end when focusing
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          persistWidget()
        })
        editor.on('ExecCommand', function (e) {
          const eventsToPersist = ['InsertOrderedList', 'InsertUnorderedList', 'indent', 'outdent']
          if (eventsToPersist.includes(e.command)) {
            persistWidget()
          }
        })
        editor.on('keydown', function (e) { // indent/outdent using tab and shift keys
          if (e.key === 'Enter' || e.key === 'Backspace' || e.key === 'Delete') {
            persistWidget()
          }
          if ((e.altKey) && // Check if combination key
                (e.code === 'KeyS')) {
            console.log('preventing default')
            e.preventDefault()
            return
          }
          if (e.keyCode === 9) {
            e.preventDefault()
            if (e.shiftKey) {
              editor.execCommand('outdent')
              persistWidget()
            } else {
              editor.execCommand('indent')
              persistWidget()
            }
          }
        })
      },
      plugins: ['lists', 'paste', 'link', 'hr'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',
      font_formats: 'Alegreya=alegreya,serif; Lato=lato,sans-serif; Merriweather=merriweather,serif; Montserrat=montserrat,serif; Open Sans=open sans,sans-serif; Oswald=oswald,sans-serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif; Roboto=roboto,sans-serif;Times New Roman=times new roman,times'
    }

    const vcoConfig = {
      handler: () => {
        isDisabled.value = true
      },
      middleware: (e) => {
        return (e.target && typeof e.target.className === 'string' && !e.target.className.match(/tox/))
      },
      events: ['dblclick', 'click'],
      isActive: true,
      detectIFrame: true,
      capture: false
    }

    return {
      editorAdvancedSettings,
      isDisabled,
      vcoConfig,
      textValue,
      persistWidget,
      getApiKey
    }
  }
})
