
import { useStore } from 'vuex'
import { computed, defineComponent, ref } from 'vue'
import IframeContainer from '@/views/mc-admin-builder/Widget/Iframe/Components/IframeContainer.vue'
import UrlContainer from '@/views/mc-admin-builder/Widget/Iframe/Components/UrlContainer.vue'

export default defineComponent({
  name: 'iframe-embedded',
  components: { UrlContainer, IframeContainer },
  props: {
    widget: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const store = useStore()
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const onDragStart = () => {
      console.log('dragstart')
    }
    const url = ref(widgetFound.value.specific.url)
    const urlIsInvalid = ref(false)
    const iframeIsDisplayed = ref(widgetFound.value.specific.url.length > 0)

    const onUrlChanged = () => {
      // ...
    }

    const onUrlPasted = (evt) => {
      // ...
    }

    const onDisplayUrlInput = () => {
      iframeIsDisplayed.value = !iframeIsDisplayed.value
    }

    const onEmbedClicked = (url) => {
      persistWidget()
      console.log('url', url)
      widgetFound.value.specific.url = url
      iframeIsDisplayed.value = true
    }
    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    return {
      widgetFound,
      url,
      urlIsInvalid,
      iframeIsDisplayed,
      onUrlChanged,
      onUrlPasted,
      onDragStart,
      onDisplayUrlInput,
      onEmbedClicked
    }
  }
})
