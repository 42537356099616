
import { computed, defineComponent, ref } from 'vue'
import { menuInfoWidgets, menuActionWidgets } from '@/core/helpers/template/widgetMenu'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'builder-widget-menu',
  props: {
    isHovered: { type: Boolean, default: false }
  },
  components: {},
  setup () {
    const store = useStore()
    const infoWidgets = ref(menuInfoWidgets)
    const actionWidgets = ref(menuActionWidgets)
    const searchInput = ref('')
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const onWidgetsSearch = () => {
      infoWidgets.value = menuInfoWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
      actionWidgets.value = menuActionWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
    }
    return {
      searchInput,
      infoWidgets,
      actionWidgets,
      activityTemplate,
      onWidgetsSearch
    }
  }
})
