
import { defineComponent } from 'vue'
import { Widget } from '@/core/helpers/template/TemplateInterface'

export default defineComponent({
  name: 'builder-widget-hotspot',
  props: {
    widget: { type: Object as () => Widget, required: true },
    widgetIndex: { type: Number, required: true }
  }
})
